html {
    overflow: hidden;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0px !important;
    overflow: hidden;
    background-color: #e4e7e9;
    font-family: -apple-system, BlinkMacSystemFont, TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    letter-spacing: 0.075rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "VMBS-font";
    src: url("./fonts/VMBS-font.eot?m0uezb");
    src: url("./fonts/VMBS-font.eot?m0uezb#iefix") format("embedded-opentype"), url("./fonts/VMBS-font.ttf?m0uezb") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="vicon-"],
[class*=" vicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "VMBS-font" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.vicon-Client-Inquiry:before {
    content: "\e900";
}

.vicon-Contact-Phone:before {
    content: "\e901";
}

.vicon-Copyright-Icon:before {
    content: "\e902";
}

.vicon-Down-Arrow:before {
    content: "\e903";
}

.vicon-Location-Address:before {
    content: "\e904";
}

.vicon-Register-Icon:before {
    content: "\e905";
}

.vicon-Triangle-Icon:before {
    content: "\e906";
}

.vicon-Vantage-Logo-Only:before {
    content: "\e907";
}

.vicon-Vantage-Logo-Text:before {
    content: "\e908";
}

.vicon-View-Icon:before {
    content: "\e909";
}

.vicon-Website-Icon:before {
    content: "\e90a";
}

.vicon-camera:before {
    content: "\e90f";
}

.vicon-folder-upload:before {
    content: "\e934";
}

.vicon-user:before {
    content: "\e971";
}

.vicon-lock:before {
    content: "\e98f";
}

.vicon-attachment:before {
    content: "\e9cd";
}

.vicon-plus:before {
    content: "\ea0a";
}

.vicon-arrow-left:before {
    content: "\ea38";
}

.vicon-facebook:before {
    content: "\ea90";
}

.vicon-instagram:before {
    content: "\ea92";
}

.vicon-twitter:before {
    content: "\ea96";
}

.vicon-linkedin2:before {
    content: "\eaca";
}

@font-face {
    font-family: 'VMBS-secFont';
    src: url('./fonts/VMBS-secFont.eot?6ipkbj');
    src: url('./fonts/VMBS-secFont.eot?6ipkbj#iefix') format('embedded-opentype'), url('./fonts/VMBS-secFont.ttf?6ipkbj') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="viconSec-"],
[class*=" viconSec-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'VMBS-secFont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.viconSec-mbsLogoFont:before {
    content: "\e900";
}

.viconSec-profile:before {
    content: "\e923";
}

.viconSec-envelop:before {
    content: "\e945";
}

.viconSec-aid-kit:before {
    content: "\e998";
}

@font-face {
  font-family: 'VMBS-inqFont';
  src:  url('./fonts/VMBS-inqFont.eot?is4jeu');
  src:  url('./fonts/VMBS-inqFont.eot?is4jeu#iefix') format('embedded-opentype'),
    url('./fonts/VMBS-inqFont.ttf?is4jeu') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="viconInq-"], [class*=" viconInq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'VMBS-inqFont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.viconInq-inquiry-icon:before {
  content: "\e900";
}
.viconInq-x-icon:before {
  content: "\e901";
}
.viconInq-profile:before {
  content: "\e923";
}
.viconInq-envelop:before {
  content: "\e945";
}
.viconInq-aid-kit:before {
  content: "\e998";
}