:root {
  --off-white: #e4e7e9;
}

div.messageSecHold {
  position: absolute;
  top: 15px;
  right: 15px;
  width: auto;
  height: auto;
  overflow: hidden;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.messageSecScroll {
  position: absolute;
  top: 0px;
  right: 0px;
  width: auto;
  height: auto;
  overflow: hidden;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.messageSecHide {
  position: absolute;
  top: -75px;
  right: 0px;
  width: auto;
  height: auto;
  overflow: hidden;
  opacity: 0;
  transition: all 260ms ease-in-out;
}
div.messageSecInActive {
  position: relative;
  width: 90px;
  height: 70px;
  top: 0px;
  right: 0px;
  background-color: rgba(8, 27, 69, 0);
  border: 3px solid rgba(8, 27, 69, 0);
  border-radius: 0px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}
div.messageSecDownInActive {
  position: relative;
  width: 82px;
  height: 60px;
  top: 0px;
  right: 0px;
  background-color: rgba(53, 70, 104, 0.8);
  border-bottom: 2px solid #59698b;
  border-left: 2px solid #59698b;
  border-right: none;
  border-top: none;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}
div.messageSecActive {
  position: relative;
  width: 360px;
  height: 375px;
  top: 0px;
  right: 0px;
  background-color: rgba(8, 27, 69, 0.8);
  border: 3px solid #4b5b7a;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}

div.messageSecThankyou {
  position: relative;
  width: 360px;
  height: 140px;
  top: 0px;
  right: 0px;
  background-color: rgba(8, 27, 69, 0.8);
  border: 3px solid #4b5b7a;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}
div.messageInputHolder {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 0px 0px 8px 0px;
  transition: all 260ms ease-in-out;
}
div.messageTextBHolder {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 0px 0px 8px 0px;
  transition: all 260ms ease-in-out;
}
div.messageSubmitHolder {
  display: block;
  position: relative;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  margin: 18px 0px 0px 0px;
  transition: all 260ms ease-in-out;
}
span.messageSubNavIcon {
  display: inline-block;
  position: relative;
  height: 16px;
  width: 20px;
  top: 4px;
  border: 2px solid #a5c3ff;
  border-radius: 2px;
  overflow: hidden;
  margin: 0px 11px 0px 0px;
  background-image: url("./images/messageNavIcon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  opacity: 0.75;
  transition: opacity 260ms ease-in-out;
}

div.submitInqButton:hover span.messageSubNavIcon {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}
div.closeHolderShow {
  position: absolute;
  display: inline-block;
  top: 8px;
  left: 8px;
  width: 35px;
  height: 35px;
  pointer-events: all;
  transition: top 160ms ease-in-out;
}
div.closeHolderHide {
  position: absolute;
  display: inline-block;
  top: -35px;
  left: 8px;
  width: 35px;
  height: 35px;
  pointer-events: none;
  transition: top 160ms ease-in-out;
}
div.messFormHolderShow {
  position: absolute;
  width: 330px;
  height: 315px;
  right: 8px;
  top: 42px;
  background-color: #4b5b7a;
  border-radius: 5px;
  border: 2px solid #2e3d5b;
  padding: 15px 15px;
  box-sizing: border-box;
  text-align: center;
  pointer-events: all;
  transition: all 260ms ease-in-out;
}

div.messFormHolderHide {
  position: absolute;
  width: 330px;
  height: 315px;
  right: -405px;
  top: 42px;
  display: none;
  background-color: #4b5b7a;
  border-radius: 5px;
  border: 2px solid #2e3d5b;
  padding: 15px 15px;
  box-sizing: border-box;
  text-align: center;
  pointer-events: none;
  transition: all 260ms ease-in-out;
}

div.thankYouMessShow {
  position: absolute;
  text-align: center;
  width: 350px;
  height: 100%;
  padding: 45px 0px 0px 0px;
  box-sizing: border-box;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 17px;
  color: #ccd7e7;
  display: block;
  bottom: 0px;
  left: 25px;
  opacity: 1;
  overflow: hidden;
  pointer-events: none;
  transition: height 260ms ease-in-out, opacity 260ms ease-in-out;
}
div.thankYouMessShow span {
  display: block;
  margin: 6px 0px 0px 0px;
  color: #bed3ff;
  font-size: 14px;
}
div.thankYouMessHide {
  position: absolute;
  text-align: center;
  width: 350px;
  height: 0%;
  padding: 45px 0px 0px 0px;
  box-sizing: border-box;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 17px;
  color: #ccd7e7;
  display: block;
  bottom: 0px;
  left: 25px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height 260ms ease-in-out, opacity 260ms ease-in-out;
}
div.messageUsBtn {
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 60px;
  box-sizing: border-box;
  overflow: hidden;
  border: 4px solid #5f6f89;
  border-radius: 6px;
  pointer-events: all;
  opacity: 1;
  transition: all 260ms ease-in-out;
}
div.messageUsBtnHide {
  position: absolute;
  display: inline-block;
  top: 0px;
  left: -90px;
  width: 80px;
  height: 60px;
  box-sizing: border-box;
  overflow: hidden;
  border: 4px solid #5f6f89;
  border-radius: 6px;
  pointer-events: none;
  opacity: 1;
  transition: all 260ms ease-in-out;
}
div.messageUsBtn:hover {
  cursor: pointer;
  border: 4px solid #7080a0;
  transition: all 260ms ease-in-out;
}
div.messageUsBtnDown {
  position: absolute;
  display: inline-block;
  top: 6px;
  left: 12px;
  width: 60px;
  height: 44px;
  box-sizing: border-box;
  overflow: hidden;
  border: 3px solid #5f6f89;
  border-radius: 3px;
  pointer-events: all;
  opacity: 0.7;
  transition: all 260ms ease-in-out;
}

div.messageUsBtnDownHide {
  position: absolute;
  display: inline-block;
  top: 8px;
  left: -70px;
  width: 60px;
  height: 44px;
  box-sizing: border-box;
  overflow: hidden;
  border: 3px solid #5f6f89;
  border-radius: 3px;
  pointer-events: none;
  transition: all 260ms ease-in-out;
}
div.messageUsBtnDown:hover {
  cursor: pointer;
  border: 3px solid #8295ba;
  transition: all 260ms ease-in-out;
}
img.messageIcon,
img.messageRollIcon {
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  margin: 0px;
}
img.messageRollIcon {
  opacity: 0;
  transition: opacity 260ms ease-in-out;
}
div.messageUsBtn:hover div img.messageRollIcon,
div.messageUsBtnDown:hover div img.messageRollIcon {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}
.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.navFullScreen {
  background: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 170px;
  height: 300px;
  z-index: 1000;
  padding: 0px;
}

.navFullScreenScroll {
  background: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 170px;
  height: 75px;
  z-index: 1001;
  pointer-events: none;
  padding: 0px;
}

div.logoMenuBtnHold {
  position: fixed;
  box-sizing: border-box;
  height: 65px;
  width: 160px;
  top: 0px;
  left: 0px;
  background-color: rgba(53, 70, 104, 0.95);
  border-bottom: 2px solid #245ea5;
  border-right: 2px solid #245ea5;
  border-bottom-right-radius: 5px;
  box-shadow: 4px 4px 18px 4px rgb(28, 73, 128, 0.1);
  pointer-events: all;
  z-index: 1060;
  overflow: hidden;
  transition: all 360ms ease-in-out;
}

div.logoMenuBtnHoldHide {
  position: fixed;
  box-sizing: border-box;
  height: 65px;
  width: 160px;
  top: 0px;
  left: -250px;
  background-color: rgba(53, 70, 104, 0.95);
  border-bottom: 2px solid #245ea5;
  border-right: 2px solid #245ea5;
  border-bottom-right-radius: 5px;
  box-shadow: 4px 4px 18px 4px rgb(28, 73, 128, 0.1);
  pointer-events: none;
  z-index: 1060;
  overflow: hidden;
  transition: all 100ms ease-in-out;
}

.greyMenuTriangle {
  position: absolute;
  height: 67px;
  width: auto;
  right: -3px;
  bottom: -1px;
  opacity: 0.7;
  display: inline-block;
}

.menuLogoScrolled {
  position: absolute;
  width: auto;
  height: 45px;
  top: 10px;
  right: 12px;
  display: inline-block;
}

.menuLogoScrolled:hover {
  cursor: pointer;
}

div.absoluteNaveHold {
  position: absolute;
  width: 90px;
  height: 0px;
  top: 0px;
  left: 10px;
  pointer-events: none;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.absoluteNaveHoldChange {
  position: absolute;
  width: 90px;
  height: 0px;
  top: -200px;
  left: 10px;
  pointer-events: none;
  overflow: hidden;
  transition: all 160ms ease-in-out;
}

div.absoluteNaveHoldHide {
  position: absolute;
  width: 90px;
  height: 0px;
  top: 0px;
  left: 10px;
  pointer-events: none;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.respMenuHolder {
  position: fixed;
  height: 90px;
  width: 95px;
  top: 0px;
  right: 0px;
  z-index: 1001;
  pointer-events: none;
  opacity: 1;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.respMenuHolderHide {
  position: fixed;
  height: 90px;
  width: 0px;
  top: 0px;
  right: 0px;
  z-index: 1001;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.respMenuHolderClose {
  position: fixed;
  height: 90px;
  width: 0px;
  top: -200px;
  right: 0px;
  z-index: 1001;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
  transition: all 160ms ease-in-out;
}

div.closeMenu {
  position: absolute;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border: 2px solid #a8b2bf;
  outline: none !important;
  border-radius: 4px;
  background-image: url("./images/VMBSButBack.svg");
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: all;
  overflow: hidden;
  transition: all 160ms ease-in-out;
}

div.relSideMenu div.closeMenu {
  top: 18px;
  right: 10px;
  transition: all 160ms ease-in-out;
}

div.mapWrapper div.closeMenu {
  top: 8px;
  left: 8px;
  transition: all 160ms ease-in-out;
}

div.closeMenu:hover {
  cursor: pointer;
  border: 2px solid #acbbd1;
  transition: all 160ms ease-in-out;
}

div.lightGreyBox {
  position: absolute;
  width: 32px;
  height: 32px;
  top: -2px;
  left: -2px;
  background-color: #7080a0;
  opacity: 1;
  transition: opacity 160ms ease-in-out;
}

div.closeMenu:hover > div.butRelInner div.lightGreyBox {
  opacity: 0;
  transition: opacity 160ms ease-in-out;
}

div.xBarOne {
  position: absolute;
  width: 18px;
  height: 3px;
  border-radius: 1px;
  top: 11px;
  left: 3px;
  background-color: #081b45;
  -ms-transform: rotate(-46deg);
  transform: rotate(-46deg);
  transition: background 160ms ease-in-out;
}

div.xBarTwo {
  position: absolute;
  width: 18px;
  height: 3px;
  border-radius: 1px;
  top: 11px;
  left: 3px;
  background-color: #081b45;
  -ms-transform: rotate(46deg);
  transform: rotate(46deg);
  transition: background 160ms ease-in-out;
}

div.closeMenu:hover > div.butRelInner div.xBarOne,
div.closeMenu:hover > div.butRelInner div.xBarTwo {
  background-color: #ffffff;
  transition: background 160ms ease-in-out;
}

div.respMenu {
  position: absolute;
  box-sizing: border-box;
  width: 45px;
  height: 41px;
  top: 25px;
  right: 25px;
  border: 3.5px solid #546b96;
  outline: none !important;
  border-radius: 6px;
  background-image: url("./images/VMBSButBack.svg");
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: all;
  overflow: hidden;
  transition: all 160ms ease-in-out;
}

div.scrollLeftMenu {
  position: absolute;
  box-sizing: border-box;
  width: 45px;
  height: 41px;
  top: 12px;
  right: 87px;
  border: 2px solid #939eb2;
  outline: none !important;
  border-radius: 4px;
  background-image: url("./images/VMBSButBack.svg");
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: all;
  overflow: hidden;
  transition: all 160ms ease-in-out;
}

div.butRelInner {
  position: relative;
  width: auto;
  height: auto;
}

div.respMenu:hover {
  cursor: pointer;
  border: 3.5px solid #245ea5;
  transition: all 160ms ease-in-out;
}

div.scrollLeftMenu:hover {
  cursor: pointer;
  border: 2px solid #caced4;
  transition: all 160ms ease-in-out;
}

div.creamBox {
  position: absolute;
  width: 53px;
  height: 49px;
  top: -3.5px;
  left: -3.5px;
  background-color: #f7fafc;
  opacity: 1;
  transition: opacity 160ms ease-in-out;
}

div.navyBox {
  position: absolute;
  width: 52px;
  height: 48px;
  top: -2.5px;
  left: -2.5px;
  background-color: #081b45;
  opacity: 1;
  transition: opacity 160ms ease-in-out;
}

div.respMenu:hover > div.butRelInner div.creamBox {
  opacity: 0;
  transition: opacity 160ms ease-in-out;
}

div.scrollLeftMenu:hover > div.butRelInner div.navyBox {
  opacity: 0;
  transition: opacity 160ms ease-in-out;
}

div.barHolder {
  position: absolute;
  top: 7.5px;
  left: 6.5px;
  width: 27px;
  height: 25px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.barHolderHide {
  position: absolute;
  top: 7.5px;
  left: 40px;
  width: 27px;
  height: 25px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.barHolderSM {
  position: absolute;
  top: 8.5px;
  left: 8px;
  width: 27px;
  height: 25px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.xBarHolder {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 34px;
  height: 34px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.xBarHolderHide {
  position: absolute;
  top: 2px;
  left: -40px;
  width: 34px;
  height: 34px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.barOneTwo {
  width: 25px;
  height: 4px;
  border-radius: 2px;
  background-color: #546b96;
  margin-bottom: 4px;
  transition: background 160ms ease-in-out;
}

div.barOneTwoSmalMen {
  width: 25px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #a4acba;
  margin-bottom: 5px;
  transition: background 160ms ease-in-out;
}

div.xBarResMenOne {
  position: absolute;
  width: 28px;
  height: 5px;
  border-radius: 2px;
  top: 13px;
  left: 3.5px;
  background-color: #546b96;
  -ms-transform: rotate(-44deg);
  transform: rotate(-44deg);
  transition: background 160ms ease-in-out;
}

div.xBarResMenTwo {
  position: absolute;
  width: 28px;
  height: 5px;
  border-radius: 2px;
  top: 13px;
  left: 3.5px;
  background-color: #546b96;
  -ms-transform: rotate(44deg);
  transform: rotate(44deg);
  transition: background 160ms ease-in-out;
}

div.respMenu:hover > div.butRelInner div.barHolder div.barOneTwo {
  background-color: #ffffff;
  transition: background 160ms ease-in-out;
}

div.scrollLeftMenu:hover
  > div.butRelInner
  div.barHolderSM
  div.barOneTwoSmalMen {
  background-color: #ffffff;
  transition: background 160ms ease-in-out;
}

div.respMenu:hover > div.butRelInner div.xBarHolder div.xBarResMenOne,
div.respMenu:hover > div.butRelInner div.xBarHolder div.xBarResMenTwo {
  background-color: #ffffff;
  transition: background 160ms ease-in-out;
}

div.hideShowMenu {
  position: fixed;
  width: 170px;
  height: 100vh;
  left: 0px;
  top: 0px;
  pointer-events: all;
  border-right: 2px solid #4b5b7a;
  box-sizing: border-box;
  background-color: rgba(46, 61, 91, 0.9);
  box-shadow: 10px 0px 10px 7px rgba(8, 27, 69, 0.1);
  z-index: 1100;
  transition: all 260ms ease-in-out;
}

div.hideSideMenu {
  position: fixed;
  width: 170px;
  height: 100vh;
  left: -200px;
  top: 0px;
  pointer-events: none;
  border-right: 2px solid #4b5b7a;
  box-sizing: border-box;
  background-color: rgba(46, 61, 91, 0.9);
  box-shadow: 10px 0px 10px 7px rgba(8, 27, 69, 0.1);
  z-index: 1100;
  transition: all 260ms ease-in-out;
}

div.relSideMenu {
  position: relative;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  padding: 70px 50px 0px 0px;
  width: 100%;
  height: 100%;
  text-align: right;
}

img.sideMenuLogo {
  width: 60px;
  height: auto;
  display: inline-block;
  margin-bottom: 20px;
  cursor: pointer;
}

img.sideMenuLogo:hover {
  cursor: pointer;
}

div.holdNavList {
  display: inline-block;
  width: 70px;
  height: 140px;
  text-align: right;
  margin: 0px;
  padding: 0px;
}

.menuLogoFull {
  position: absolute;
  width: 45px;
  height: auto;
  display: block;
  top: 25px;
  right: 25px;
  transition: all 260ms ease-in-out;
}

.menuLogoFull:hover {
  cursor: pointer;
}

.topBotTextImg {
  height: 118px;
  transition: all 260ms ease-in-out;
}

.absoluteItems {
  position: absolute;
  height: 0px;
  width: 100%;
  top: 105px;
  right: 0px;
  text-align: right;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navItems {
  padding: 0px !important;
  margin: 0px !important;
}

.navItem {
  display: block;
  margin-bottom: 12px;
  text-align: right;
}

.navItem button.linkButton {
  font-family: Lora, serif;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  display: inline-block;
  height: auto;
  width: auto;
  padding: 0px 5px 0px 0px !important;
  color: #acbbd1;
  margin: 0px !important;
  outline: none !important;
  border: none !important;
  background: none !important;
}

.navItem:hover {
  cursor: pointer;
}

.navItem:hover button.linkButton,
button.linkButton:hover {
  color: #ffffff;
  cursor: pointer;
}
div.navMessageBtn {
  position: absolute;
  top: 292px;
  left: 72px;
  width: 52px;
  height: 39px;
  overflow: hidden;
  z-index: 1100;
  display: none;
  margin: 0px 0px 0px 5px;
  border-width: 3px;
  border-style: solid;
  border-color: #667894;
  border-radius: 2px;
  padding: 0px;
  box-sizing: border-box;
  pointer-events: all;
  transition: all 260ms ease-in-out;
}

div.navMessageBtn:hover {
  cursor: pointer;
  border-color: #245ea5;
  transition: all 260ms ease-in-out;
}

img.messIconNav {
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0.8;
  height: auto;
}
img.messIconNavRoll {
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 260ms ease-in-out;
}
div.navMessageBtn:hover div img.messIconNavRoll {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}
.navItem > .active {
  color: #e4e7e9;
}
.siteWrapperTop {
  position: relative;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(228, 231, 233, 1);
  transition: background 260ms ease-in-out;
}

div.siteWrapperMove {
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 27, 69, 0.35);
  transition: background 260ms ease-in-out;
}

div.siteWrapperDown {
  position: fixed;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 27, 69, 1);
  transition: background 260ms ease-in-out;
}

.section {
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

div.homeSecWrapper {
  position: relative;
  display: block;
  height: 600px;
  width: 100%;
  top: 0px;
  left: 0px;
  transition: all 260ms ease-in-out;
}

div.homeSecOuter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: none;
  transition: transform 0s;
}

div.homeNavyOverlay {
  position: absolute;
  width: 100%;
  height: 180%;
  left: 0px;
  top: 0px;
  pointer-events: none;
  background-color: #3d507a;
}

div.homeBlueishOverlay {
  position: fixed;
  width: 100%;
  height: 110%;
  left: 0px;
  top: 0px;
  pointer-events: none;
  background-color: rgba(81, 108, 161, 0.6);
  transition: all 260ms ease-in-out;
}

div.homeBlueishHide {
  position: fixed;
  width: 100%;
  height: 110%;
  left: 0px;
  top: 0px;
  pointer-events: none;
  background-color: rgba(81, 108, 161, 0);
  transition: all 260ms ease-in-out;
}

div.homeTopLeft {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

div.relWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.homeLogoImg {
  position: absolute;
  display: inline-block;
  width: 160px;
  margin-left: 0px;
  margin-top: 0px;
  height: auto;
  top: 80px;
  left: 80px;
  transition: all 360ms ease-in-out;
}

.homeLogoImgHid {
  position: absolute;
  display: inline-block;
  width: 276px;
  margin-left: -138px;
  margin-top: -151px;
  height: auto;
  top: 50vh;
  left: 50%;
  transition: all 260ms ease-in-out;
}

.homeTopLeftImg {
  position: absolute;
  display: inline-block;
  width: 350px;
  height: auto;
  top: -1px;
  left: -1px;
  opacity: 1;
  transition: all 360ms ease-in-out;
}

.homeTopLeftImgHid {
  position: absolute;
  display: inline-block;
  width: 300px;
  height: auto;
  top: -1px;
  left: -300px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.homeBottomRight {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  right: 0px;
}

.homeBottomRightImg {
  position: absolute;
  display: inline-block;
  width: 420px;
  height: auto;
  bottom: 0px;
  right: -1px;
  opacity: 1;
  transition: all 360ms ease-in-out;
}

.homeTopRightImgHid {
  position: absolute;
  display: inline-block;
  width: 320px;
  height: auto;
  bottom: 0px;
  right: -320px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.topTextHolder {
  position: absolute;
  display: inline-block;
  height: 200px;
  width: 325px;
  overflow: hidden;
  top: 100px;
  left: 260px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.topTextHolderHide {
  position: absolute;
  display: inline-block;
  height: 200px;
  width: 0px;
  overflow: hidden;
  top: 100px;
  left: 260px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.topBotTextHolder {
  position: absolute;
  display: inline-block;
  height: 250px;
  width: 250px;
  overflow: hidden;
  top: 250px;
  left: 102px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.topBotTextHolderHide {
  position: absolute;
  display: inline-block;
  height: 0px;
  width: 250px;
  overflow: hidden;
  top: 250px;
  left: 102px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

.topTextImg {
  position: absolute;
  display: inline-block;
  height: 110px;
  width: auto;
  left: 0px;
  top: 0px;
  transition: all 260ms ease-in-out;
}

.topBotTextImg {
  position: absolute;
  display: inline-block;
  height: 100px;
  width: auto;
  left: 0px;
  top: 0px;
  transition: all 260ms ease-in-out;
}

#mainScrollArea {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.mainScrollTop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(8, 27, 69, 0.4);
  background-image: url("./images/scrollTrackTop.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  border-radius: 10px;
  transition: all 100ms ease-in-out;
}

.mainScrollTop::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background-color: #37486c;
  transition: all 100ms ease-in-out;
}

.mainScrollTop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: url("./images/scrollbarImage.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  transition: all 100ms ease-in-out;
}

.mainScrollAbout::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(228, 231, 233, 0.65);
  background-image: url("./images/scrollTrackAbout.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  border-radius: 10px;
  transition: all 100ms ease-in-out;
}

.mainScrollAbout::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background-color: #e4e7e9;
  transition: all 100ms ease-in-out;
}

.mainScrollAbout::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: url("./images/scrollBarAbout.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  transition: all 100ms ease-in-out;
}

.mainScrollService::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(46, 61, 91, 0.3);
  background-image: url("./images/scrollTrackService.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  border-radius: 10px;
  transition: all 100ms ease-in-out;
}

.mainScrollService::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background-color: #6a7997;
  transition: all 100ms ease-in-out;
}

.mainScrollService::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: url("./images/scrollBarThumBot.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  transition: all 100ms ease-in-out;
}

.mainScrollContact::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(8, 27, 69, 0.3);
  background-image: url("./images/scrollTrackBottom.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  border-radius: 10px;
  transition: all 100ms ease-in-out;
}

.mainScrollContact::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background-color: #3e4d6b;
  transition: all 100ms ease-in-out;
}

.mainScrollContact::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: url("./images/scrollBarThumBot.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  transition: all 100ms ease-in-out;
}

.container {
  display: inline-block;
  cursor: pointer;
}

/* about section starts here */

div.aboutSectionHolder {
  position: relative;
  height: 1020px;
  padding: 0px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
  background: none;
  text-align: left;
  overflow: hidden;
  transition: height 260ms ease-in-out;
}

div.aboutSectionInfo {
  position: relative;
  height: 1400px;
  padding: 0px 20px 0px 20px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: #e4e7e9;
  text-align: left;
  overflow: hidden;
  transition: height 260ms ease-in-out;
}

div.aboutSectionVantage {
  position: relative;
  height: 1400px;
  padding: 0px 20px 0px 20px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: #e4e7e9;
  text-align: left;
  overflow: hidden;
  transition: height 260ms ease-in-out;
}

div.aboutSectionMBS {
  position: relative;
  height: 1820px;
  padding: 0px 20px 0px 20px;
  margin: 0px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: #e4e7e9;
  text-align: left;
  overflow: hidden;
  transition: height 260ms ease-in-out;
}

div.aboutSectionOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  border-top: 3px solid #ffffff;
  border-bottom: 4px solid #c9d0d9;
  background-color: #edf0f2;
  opacity: 1;
  overflow: hidden;
  pointer-events: all;
  transition: height 260ms ease-in-out, opacity 260ms ease-in-out;
}

div.aboutSectionHidden {
  position: absolute;
  height: 0%;
  width: 100%;
  top: 0px;
  left: 0px;
  border-top: 3px solid #ffffff;
  border-bottom: 4px solid #c9d0d9;
  background-color: #edf0f2;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height 260ms ease-in-out, opacity 260ms ease-in-out;
}

div.sectionAboutShow {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 1;
  pointer-events: all;
  padding: 100px 0px 0px 0px;
  text-align: center;
  box-sizing: border-box;
  transition: height 260ms ease-in-out, opacity 260ms ease-in-out;
}

div.sectionAboutHide {
  position: absolute;
  height: 0%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  padding: 100px 0px 0px 0px;
  text-align: center;
  box-sizing: border-box;
  transition: height 260ms ease-in-out, opacity 260ms ease-in-out;
}

div.aboutInfoWrapper {
  position: relative;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.aboutInfoBtnbottomHide {
  position: absolute;
  display: block;
  bottom: -120px;
  left: 0px;
  height: 100px;
  width: 100%;
  text-align: center;
  padding: 20px 0px 0px 0px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.aboutInfoBtnbottomHold {
  position: absolute;
  display: block;
  bottom: 0px;
  left: 0px;
  height: 100px;
  width: 100%;
  text-align: center;
  padding: 20px 0px 0px 0px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.aboutInfoBtnHold {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  height: 100px;
  width: 100%;
  text-align: center;
  padding: 20px 0px 0px 0px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.aboutInfoBtnHide {
  position: absolute;
  display: block;
  top: -120px;
  left: 0px;
  height: 100px;
  width: 100%;
  text-align: center;
  padding: 20px 0px 0px 0px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.inBtnHolder {
  display: inline-block;
  width: 230px;
  height: 60px;
  position: relative;
  vertical-align: top;
  text-align: right;
  margin-left: -270px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}

div.inBtnBottomHolder {
  display: inline-block;
  width: 230px;
  height: 60px;
  position: relative;
  vertical-align: top;
  text-align: left;
  margin-left: -270px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}

div.VantageInfoButton {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  height: 55px;
  width: 55px;
  overflow: hidden;
  border: 4px solid #991932;
  background-color: #edf0f2;
  font-size: 36px;
  color: #991932;
  text-align: center;
  padding: 6px 0px 0px 0px;
  border-radius: 6px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}

div.VantageInfoButton:hover {
  color: #ffffff;
  cursor: pointer;
  border: 4px solid #7687a8;
  background-color: #991932;
  transition: all 260ms ease-in-out;
}

div.MBSInfoButton {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  height: 55px;
  width: 55px;
  overflow: hidden;
  border: 4px solid #5f6f89;
  background-color: #edf0f2;
  font-size: 36px;
  color: #081b45;
  text-align: center;
  padding: 6px 0px 0px 0px;
  border-radius: 6px;
  box-sizing: border-box;
  transition: all 260ms ease-in-out;
}

div.MBSInfoButton:hover {
  color: #ffffff;
  cursor: pointer;
  border: 4px solid #7687a8;
  background-color: #081b45;
  transition: all 260ms ease-in-out;
}

div.backInfoButton {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  height: 55px;
  width: 55px;
  overflow: hidden;
  border: 4px solid #5f6f89;
  border-radius: 6px;
  box-sizing: border-box;
  background-image: url("./images/VMBSButBack.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all 260ms ease-in-out;
}

div.backInfoButton div.whiteBtnBack {
  display: block;
  position: absolute;
  background-color: rgba(237, 240, 242, 1);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  font-size: 32px;
  text-align: center;
  padding: 8px 0px 0px 0px;
  box-sizing: border-box;
  transition: background 260ms ease-in-out;
}

div.backInfoButton div.whiteBtnBack span.vicon-arrow-left {
  position: relative;
  display: inline-block;
  color: #5f6f89;
  transition: color 260ms ease-in-out;
}

div.backInfoButton div.partnerIconBack {
  position: absolute;
  display: block;
  left: 1px;
  top: 0.5px;
  width: 46px;
  height: 46px;
  background-image: url("./images/partnership_icon_only.svg");
  background-size: auto 30px;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}

div.backInfoButton div.partnerIconWhite {
  position: absolute;
  display: block;
  left: 1px;
  top: 0.5px;
  width: 46px;
  height: 46px;
  background-image: url("./images/partnership_icon_only_white.svg");
  background-size: auto 30px;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 260ms ease-in-out;
}

div.backInfoButton:hover {
  cursor: pointer;
  border: 4px solid #081b45;
  transition: all 260ms ease-in-out;
}

div.backInfoButton:hover div.whiteBtnBack {
  background-color: rgba(237, 240, 242, 0);
  transition: background 260ms ease-in-out;
}

div.backInfoButton:hover div.whiteBtnBack span.vicon-arrow-left {
  color: #ffffff;
  transition: color 260ms ease-in-out;
}

div.backInfoButton:hover div.partnerIconBack {
  opacity: 0;
  transition: opacity 260ms ease-in-out;
}

div.backInfoButton:hover div.partnerIconWhite {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}

div.aboutInfoScrollBar {
  position: relative;
  font-family: "Lora", serif;
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 0px 20px 0px 20px;
  margin: 0px 0px 0px 0px !important;
  transition: all 260ms ease-in-out;
}

h4.aboutInfoSecHead {
  color: #9b2743;
  letter-spacing: 0.075rem;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  margin: 0px 0px 16px 0px;
}

h4.aboutMBSSecHead {
  color: #2e3d5b;
  letter-spacing: 0.075rem;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  margin: 0px 0px 16px 0px;
}

p.infoSecParagraph {
  letter-spacing: 0.08rem;
  color: #4d5a6f;
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  line-height: 1.6;
  margin: 0px 0px 16px 0px;
}

p.topVantageTextPara {
  letter-spacing: 0.08rem;
  color: #4d5a6f;
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  line-height: 1.6;
  margin: 0px 0px 16px 0px;
}

div.vantageBuidlingHold {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 450px;
  height: 361px;
  border: 4px solid #7186b0;
  padding: 0px;
  vertical-align: top;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  margin: 0px 40px 0px 0px;
  transition: all 260ms ease-in-out;
}

div.vantageBuidlingHold:hover {
  cursor: pointer;
  border: 4px solid #9b2743;
  transition: all 260ms ease-in-out;
}

div.mbsIllustration {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 450px;
  height: 361px;
  border: 4px solid #7186b0;
  padding: 0px;
  vertical-align: top;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  margin: 0px 40px 0px 0px;
  transition: all 260ms ease-in-out;
}

div.mbsIllustration:hover {
  cursor: pointer;
  border: 4px solid 081b45;
  transition: all 260ms ease-in-out;
}

img.vantageBuilding {
  display: inline-block;
  width: 450px;
  height: 361px;
  transition: all 260ms ease-in-out;
}

div.pictureOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  pointer-events: none;
  background-color: #081b45;
  transition: opacity 260ms ease-in-out;
}

div.mbsSolutionsText {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 78%;
  height: 76px;
  border-top-left-radius: 6px;
  border-top: 2.5px solid #6a7997;
  border-left: 2.5px solid #6a7997;
  box-sizing: border-box;
  background-image: url("./images/mbsSolutionsText.svg");
  background-repeat: no-repeat;
  background-size: 82% auto;
  background-position: center center;
  opacity: 0.8;
  transition: opacity 260ms ease-in-out;
}

div.vantageHealthNetText {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 78%;
  height: 76px;
  border-top-left-radius: 6px;
  border-top: 2.5px solid #6a7997;
  border-left: 2.5px solid #6a7997;
  box-sizing: border-box;
  background-image: url("./images/vantageHealthcareNetwork.svg");
  background-repeat: no-repeat;
  background-size: 82% auto;
  background-position: center center;
  opacity: 0.8;
  transition: opacity 260ms ease-in-out;
}

div.vantageHealthNetback {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 78%;
  height: 76px;
  border-top-left-radius: 6px;
  border-top: 2.5px solid #6a7997;
  border-left: 2.5px solid #6a7997;
  box-sizing: border-box;
  opacity: 0.35;
  background-color: #081b45;
  transition: all 260ms ease-in-out;
}

div.mbsSolutionsback {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 78%;
  height: 76px;
  border-top-left-radius: 6px;
  border-top: 2.5px solid #6a7997;
  border-left: 2.5px solid #6a7997;
  box-sizing: border-box;
  opacity: 0.35;
  background-color: #081b45;
  transition: all 260ms ease-in-out;
}

div.vantageBuidlingHold:hover div.pictureOverlay {
  opacity: 0.25;
  transition: opacity 260ms ease-in-out;
}

div.mbsIllustration:hover div.pictureOverlay {
  opacity: 0.25;
  transition: opacity 260ms ease-in-out;
}

div.vantageBuidlingHold:hover div.vantageHealthNetText {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}

div.vantageBuidlingHold:hover div.vantageHealthNetback {
  opacity: 0.9;
  background-color: #9b2743;
  transition: all 260ms ease-in-out;
}

div.mbsIllustration:hover div.mbsSolutionsText {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}

div.mbsIllustration:hover div.mbsSolutionsback {
  opacity: 0.9;
  background-color: #081b45;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 340px;
  float: left;
  border-top: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
  border-bottom: 4px solid #c9d0d9;
  box-sizing: border-box;
  background-color: #e4e7e9;
  overflow: visible;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 340px;
  float: left;
  border-top: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
  border-bottom: 4px solid #e4e7e9;
  box-sizing: border-box;
  background-color: #e4e7e9;
  overflow: visible;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton {
  position: absolute;
  display: inline-block;
  width: 35px;
  height: 35px;
  top: 0px;
  right: 0px;
  overflow: hidden;
  border-bottom: 4px solid #9eabc0;
  border-left: 4px solid #9eabc0;
  border-bottom-left-radius: 6px;
  background-color: #081b45;
  color: #d1d6dc;
  transition: all 260ms ease-in-out;
}

div.vantageWebButton {
  position: absolute;
  display: inline-block;
  width: 35px;
  height: 35px;
  top: 0px;
  right: 0px;
  overflow: hidden;
  border-bottom-left-radius: 6px;
  background-color: #9b2743;
  color: #d1d6dc;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn div.vantageWebButton {
  border-bottom: 4px solid #9eabc0;
  border-left: 4px solid #9eabc0;
  transition: all 260ms ease-in-out;
}

div.vantageBuidlingHold div.vantageWebButton {
  border-bottom: 3px solid #6a7997;
  border-left: 3px solid #6a7997;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSWebButton {
  color: #e4e7e9;
  border-bottom: 4px solid #94a0b4;
  border-left: 4px solid #94a0b4;
  width: 165px;
  transition: all 260ms ease-in-out;
}

div.mbsIllustration:hover div.MBSWebButton {
  color: #e4e7e9;
  border-bottom: 4px solid #94a0b4;
  border-left: 4px solid #94a0b4;
  width: 165px;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSImgHolderReg {
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSImgHolderWhite {
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover div.vantageWebButton {
  color: #e4e7e9;
  border-bottom: 4px solid #94a0b4;
  border-left: 4px solid #94a0b4;
  width: 165px;
  transition: all 260ms ease-in-out;
}

div.vantageBuidlingHold:hover div.vantageWebButton {
  color: #e4e7e9;
  border-bottom: 3px solid #617397 !important;
  border-left: 3px solid #617397 !important;
  width: 165px;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover div.vantageImgHolderReg {
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover div.vantageImgHolderWhite {
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn div.vantageWebButton:hover {
  background-color: #eff1f2;
  border-bottom: 4px solid #5976b0 !important;
  border-left: 4px solid #5976b0 !important;
  width: 165px;
  cursor: pointer;
  transition: all 260ms ease-in-out;
}

div.vantageBuidlingHold div.vantageWebButton:hover {
  background-color: #eff1f2;
  border-bottom: 3px solid #9b2743 !important;
  border-left: 3px solid #9b2743 !important;
  width: 165px;
  cursor: pointer;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton:hover {
  background-color: #eff6ff;
  border-bottom: 4px solid #5976b0 !important;
  border-left: 4px solid #5976b0 !important;
  width: 165px;
  cursor: pointer;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSWebButton > div.topRtBtnRelative div.MBSSiteText,
div.MBSWebButton:hover > div.topRtBtnRelative div.MBSSiteText {
  left: 40px;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton:hover > div.topRtBtnRelative div.MBSSiteText {
  color: #081b45;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover
  div.vantageWebButton
  > div.topVanBtnRelative
  div.vVanSiteText,
div.vantageWebButton:hover > div.topVanBtnRelative div.vVanSiteText,
div.vantageBuidlingHold:hover
  div.vantageWebButton
  > div.topVanBtnRelative
  div.vVanSiteText {
  left: 45px;
  transition: all 260ms ease-in-out;
}

div.vantageWebButton:hover > div.topVanBtnRelative div.vVanSiteText {
  color: #9b2743;
  transition: all 260ms ease-in-out;
}

div.topVanBtnRelative {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

div.topRtBtnRelative {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

div.MBSWebButton > div.topRtBtnRelative span.vicon-Website-Icon {
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 25px;
  opacity: 1;
  font-size: 22px;
  top: 6.5px;
  left: 6px;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover
  div.vantageWebButton
  > div.topVanBtnRelative
  span.vicon-Website-Icon,
div.vantageWebButton:hover > div.topRtBtnRelative span.vicon-Website-Icon,
div.vantageBuidlingHold:hover
  div.vantageWebButton
  > div.topVanBtnRelative
  span.vicon-Website-Icon {
  left: 12px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover
  div.MBSWebButton
  > div.topRtBtnRelative
  span.vicon-Website-Icon,
div.MBSWebButton:hover > div.topRtBtnRelative span.vicon-Website-Icon,
div.mbsIllustration:hover
  div.MBSWebButton
  > div.topRtBtnRelative
  span.vicon-Website-Icon {
  left: 14px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton:hover > div.topRtBtnRelative span.vicon-Website-Icon {
  color: #081b45;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton > div.topRtBtnRelative span.span.vicon-Website-Icon {
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 25px;
  opacity: 0;
  font-size: 22px;
  top: 6.5px;
  left: 6px;
  transition: opacity 260ms ease-in-out;
}

div.MBSWebButton > div.topRtBtnRelative div.MBSSiteText {
  position: absolute;
  display: inline-block;
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.065rem;
  height: 25px;
  box-sizing: border-box;
  width: 120px;
  padding: 7px 0px 0px 0px;
  left: 40px;
  top: 4.5px;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton > div.topRtBtnRelative div.MBSSiteText b {
  display: inline;
  font-weight: 600;
}

div.vantageWebButton > div.topVanBtnRelative span.vicon-Website-Icon {
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 25px;
  opacity: 1;
  font-size: 22px;
  top: 6.5px;
  left: 6px;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton > div.topRtBtnRelative span.viconSec-mbsLogoFont {
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 25px;
  opacity: 0;
  font-size: 22px;
  top: 6.5px;
  left: 6px;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover
  div.MBSWebButton
  > div.topRtBtnRelative
  span.viconSec-mbsLogoFont,
div.MBSWebButton:hover > div.topRtBtnRelative span.viconSec-mbsLogoFont,
div.mbsIllustration:hover
  div.MBSWebButton
  > div.topRtBtnRelative
  span.viconSec-mbsLogoFont {
  left: 14px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.MBSWebButton:hover > div.topRtBtnRelative span.viconSec-mbsLogoFont {
  color: #081b45;
  transition: all 260ms ease-in-out;
}

div.vantageWebButton > div.topVanBtnRelative span.vicon-Vantage-Logo-Only {
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 25px;
  opacity: 0;
  font-size: 22px;
  top: 6.5px;
  left: 6px;
  transition: opacity 260ms ease-in-out;
}

div.vantageAboutBtn:hover
  div.vantageWebButton
  > div.topVanBtnRelative
  span.vicon-Vantage-Logo-Only,
div.vantageWebButton:hover > div.topVanBtnRelative span.vicon-Vantage-Logo-Only,
div.vantageBuidlingHold:hover
  div.vantageWebButton
  > div.topVanBtnRelative
  span.vicon-Vantage-Logo-Only {
  left: 12px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.vantageWebButton:hover
  > div.topVanBtnRelative
  span.vicon-Vantage-Logo-Only {
  color: #9b2743;
  transition: all 260ms ease-in-out;
}

div.vantageWebButton > div.topVanBtnRelative div.vVanSiteText {
  position: absolute;
  display: inline-block;
  font-family: "Times New Roman", Times, serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.065rem;
  height: 25px;
  box-sizing: border-box;
  width: 115px;
  padding: 7px 0px 0px 0px;
  left: 40px;
  top: 4.5px;
  transition: all 260ms ease-in-out;
}

div.vantageWebButton > div.topVanBtnRelative div.vVanSiteText b {
  display: inline;
  font-weight: 700;
}

div.MBSSolutionsBtn {
  position: absolute;
  box-sizing: border-box;
  width: 215px;
  height: 50px;
  bottom: -4px;
  left: -4px;
  border-top: 3.5px solid #ffffff;
  border-right: 3.5px solid #ffffff;
  border-left: 4px solid #caced4;
  border-bottom: 4px solid #9eabc0;
  border-top-right-radius: 6px;
  background-color: #d1d6dc;
  overflow: hidden;
  cursor: pointer;
  clip-path: polygon(-25px 48px, 48px 250px, 250px 250px, 250px -178px);
  transition: all 260ms ease-in-out;
}

div.abtHealthNetwork {
  position: absolute;
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  bottom: -4px;
  left: -4px;
  border-top: 3.5px solid #ffffff;
  border-right: 3.5px solid #ffffff;
  border-left: 4px solid #caced4;
  border-bottom: 4px solid #9eabc0;
  border-top-right-radius: 6px;
  background-color: #d1d6dc;
  overflow: hidden;
  cursor: pointer;
  clip-path: polygon(-25px 48px, 48px 250px, 250px 250px, 250px -178px);
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSSolutionsBtn,
div.MBSSolutionsBtn:hover {
  border-right: 3.5px solid #c5d6f0;
  border-bottom: 4px solid #7b91be;
  border-top: 3.5px solid #c5d6f0;
  background-color: #2e3d5b;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover div.abtHealthNetwork,
div.abtHealthNetwork:hover {
  border-right: 3.5px solid #c5d6f0;
  border-bottom: 4px solid #7b91be;
  border-top: 3.5px solid #c5d6f0;
  background-color: #2e3d5b;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSSolutionsBtn > div.butRelInner div.whiteTriStand,
div.MBSSolutionsBtn:hover > div.butRelInner div.whiteTriStand {
  background-color: #c5d6f0;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover
  div.abtHealthNetwork
  > div.butRelInner
  div.whiteTriStand,
div.abtHealthNetwork:hover > div.butRelInner div.whiteTriStand {
  background-color: #c5d6f0;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover div.MBSSolutionsBtn > div.butRelInner div.btnTextMBS,
div.MBSSolutionsBtn:hover > div.butRelInner div.btnTextMBS {
  color: #d3dbec;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover
  div.abtHealthNetwork
  > div.butRelInner
  div.btnTextStand,
div.abtHealthNetwork:hover > div.butRelInner div.btnTextStand {
  color: #d3dbec;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover
  div.MBSSolutionsBtn
  > div.butRelInner
  div.btnTextMBS
  div.circleInfoMBS,
div.MBSSolutionsBtn:hover > div.butRelInner div.btnTextMBS div.circleInfoMBS {
  color: #d3dbec;
  border: 3px solid #d3dbec;
  background-color: #7b88a2;
  transition: all 260ms ease-in-out;
}

div.vantageAboutBtn:hover
  div.abtHealthNetwork
  > div.butRelInner
  div.btnTextStand
  div.circleInfoVant,
div.abtHealthNetwork:hover
  > div.butRelInner
  div.btnTextStand
  div.circleInfoVant {
  color: #d3dbec;
  border: 3px solid #d3dbec;
  background-color: #9b2743;
  transition: all 260ms ease-in-out;
}

div.whiteTriStand {
  position: absolute;
  top: 0px;
  left: -31px;
  width: 75px;
  height: 52px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #ffffff;
  clip-path: polygon(25% 0%, 100% 0%, 11% 100%, 0% 100%);
  transition: all 260ms ease-in-out;
}

div.btnTextMBS {
  position: absolute;
  top: 0px;
  left: 38px;
  box-sizing: border-box;
  width: 185px;
  height: 44px;
  font-family: "Lora", serif;
  font-weight: 400;
  color: #7386ac;
  font-size: 13px;
  padding: 10.5px 10px 0px 0px;
  margin: 0px;
  text-align: left;
  transition: all 260ms ease-in-out;
}

div.btnTextStand {
  position: absolute;
  top: 0px;
  left: 38px;
  box-sizing: border-box;
  width: 200px;
  height: 44px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  color: #7386ac;
  font-size: 13px;
  padding: 10.5px 10px 0px 0px;
  margin: 0px;
  text-align: left;
  transition: all 260ms ease-in-out;
}

div.circleInfoMBS {
  position: relative;
  display: inline-block;
  height: 23px;
  width: 23px;
  box-sizing: border-box;
  border: 3px solid #9eabc0;
  color: #7386ac;
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding-top: 0px 0px 0px 0px;
  margin-right: 8px;
  transition: all 260ms ease-in-out;
}

div.circleInfoMBS > b {
  position: relative;
  display: inline-block;
  top: -2px;
}

div.circleInfoVant {
  position: relative;
  display: inline-block;
  height: 23px;
  width: 23px;
  box-sizing: border-box;
  border: 3px solid #9eabc0;
  color: #7386ac;
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  font-size: 16px;
  padding-top: 0px 0px 0px 0px;
  margin-right: 8px;
  transition: all 260ms ease-in-out;
}

div.circleInfoVant > b {
  position: relative;
  display: inline-block;
  top: -1px;
}

div.abtBtnInStand {
  position: relative;
  display: inline-block;
  width: auto;
  height: 27px;
  letter-spacing: 0.095rem;
  box-sizing: border-box;
  padding: 0px 0px 0px 0px;
  top: -1px;
}

div.vantageAboutBtn div.leftbuttonBorder,
div.MBSAboutBtn div.leftbuttonBorder {
  position: absolute;
  height: 400px;
  width: 4px;
  top: -4px;
  left: -4px;
  background-color: #91abe0;
  opacity: 0;
  transition: opacity 260ms ease-in-out;
}

div.vantageAboutBtn:hover div.leftbuttonBorder,
div.MBSAboutBtn:hover div.leftbuttonBorder {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}

div.vantageAboutBtn:hover {
  border-top: 4px solid #c5d6f0;
  border-right: 4px solid #7b91be;
  border-bottom: 4px solid #7b91be;
  background-color: #5e6c89;
  cursor: pointer;
  transition: all 260ms ease-in-out;
}

div.MBSAboutBtn:hover {
  border-top: 4px solid #c5d6f0;
  border-right: 4px solid #7b91be;
  border-bottom: 4px solid #7b91be;
  background-color: #081b45;
  cursor: pointer;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 340px;
  float: left;
  border-right: 4px solid #ffffff;
  box-sizing: border-box;
  border-bottom: 4px solid #e4e7e9;
  background: none;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover {
  border-right: 4px solid #7b91be;
  border-bottom: 4px solid #7b91be;
  cursor: pointer;
}

div.ourPartDivColor {
  position: absolute;
  display: inline-block;
  width: 1838px;
  height: 5060px;
  top: 0px;
  left: -340px;
  box-sizing: border-box;
  border-top: 4px solid #ffffff;
  background-color: #e4e7e9;
  clip-path: polygon(1117px -683px, 2238px 0px, 1556px 1556px, -70px 415px);
  transition: all 260ms ease-in-out;
}

div.relativePartBtn {
  position: relative;
  height: 400px;
  top: 0px;
  left: 340px;
  pointer-events: none;
  display: inline-block;
  box-sizing: border-box;
}

div.abtOurPartBtn {
  position: absolute;
  box-sizing: border-box;
  width: 170px;
  height: 46px;
  top: 0px;
  left: 0px;
  border-bottom: 3.5px solid #9eabc0;
  border-right: 3.5px solid #9eabc0;
  border-bottom-right-radius: 6px;
  background-color: #d1d6dc;
  overflow: hidden;
  cursor: pointer;
  pointer-events: all;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover
  div.ourPartDivColor
  > div.relativePartBtn
  div.abtOurPartBtn,
div.abtOurPartBtn:hover {
  cursor: pointer;
  border-bottom: 3.5px solid #7b91be;
  border-right: 3.5px solid #7b91be;
  background-color: #2e3d5b;
  transition: all 260ms ease-in-out;
}

div.redTriangleDiv {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 101.5%;
  height: 100%;
  box-sizing: border-box;
  background-color: #081b45;
  opacity: 0;
  pointer-events: none;
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  transition: opacity 260ms ease-in-out;
}

div.partTextImgHolderReg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -66px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  background-image: url("./images/OurPartnershipText.svg");
  background-repeat: no-repeat;
  background-size: auto 90px;
  background-position: center center;
  border-top: 4px solid #e4e7e9;
  border-left: 4px solid #e4e7e9;
  border-bottom: 4px solid #e4e7e9;
  border-right: 4px solid #e4e7e9;
  border-radius: 5px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.partTextImgHolderWhite {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -66px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: auto 90px;
  background-position: center center;
  background-image: url("./images/OurPartnershipTextWhite.svg");
  border-top: 4px solid #0d244d;
  border-left: 4px solid #0d244d;
  border-bottom: 4px solid #5e6c89;
  border-right: 4px solid #5e6c89;
  border-radius: 5px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.vantageImgHolderReg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -66px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  background-image: url("./images/VantageTextLogo.svg");
  background-repeat: no-repeat;
  background-size: auto 90px;
  background-position: center center;
  border-radius: 5px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.vantageImgHolderWhite {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -66px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: auto 90px;
  background-position: center center;
  background-image: url("./images/VantageTextLogoWhite.svg");
  border-radius: 5px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.MBSImgHolderReg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -66px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  background-image: url("./images/MillerBrotherLogoText.svg");
  background-repeat: no-repeat;
  background-size: auto 90px;
  background-position: center center;
  border-radius: 5px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.MBSImgHolderWhite {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -156px;
  margin-top: -66px;
  width: 310px;
  height: 130px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: auto 90px;
  background-position: center center;
  background-image: url("./images/MillerBrotherLogoTextWhite.svg");
  border-radius: 5px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.whiteTriangleDiv {
  position: absolute;
  top: 0px;
  left: -27px;
  width: 75px;
  height: 48px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #ffffff;
  clip-path: polygon(25% 0%, 100% 0%, 27.5% 100%, 0% 100%);
  transition: all 260ms ease-in-out;
}

div.btnTextHolder {
  position: absolute;
  top: 0px;
  left: 50px;
  box-sizing: border-box;
  width: 130px;
  height: 44px;
  font-family: Lora, serif;
  font-weight: 600;
  color: #7386ac;
  font-size: 17px;
  padding: 10px 10px 0px 0px;
  margin: 0px;
  text-align: left;
  transition: all 260ms ease-in-out;
}

div.circleInfoIcon {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 25px;
  box-sizing: border-box;
  border: 3px solid #9eabc0;
  color: #7386ac;
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding-top: 4px;
  margin-right: 9px;
  transition: all 260ms ease-in-out;
}

div.circleInfoIcon > b {
  position: relative;
  display: inline-block;
  top: -5px;
}

div.abtBtnInBlText {
  position: relative;
  display: inline-block;
  width: auto;
  height: 25px;
  letter-spacing: 0.095rem;
  box-sizing: border-box;
  padding: 0px 0px 0px 0px;
  top: -3.5px;
}

div.ourPartnershipBtn:hover
  div.ourPartDivColor
  > div.relativePartBtn
  div.abtOurPartBtn
  > div.butRelInner
  div.whiteTriangleDiv,
div.abtOurPartBtn:hover > div.butRelInner div.whiteTriangleDiv {
  background-color: #c5d6f0;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover
  div.ourPartDivColor
  > div.relativePartBtn
  div.abtOurPartBtn
  > div.butRelInner
  div.btnTextHolder,
div.abtOurPartBtn:hover > div.butRelInner div.btnTextHolder {
  color: #d3dbec;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover
  div.ourPartDivColor
  > div.relativePartBtn
  div.abtOurPartBtn
  > div.butRelInner
  div.btnTextHolder
  div.circleInfoIcon,
div.abtOurPartBtn:hover > div.butRelInner div.btnTextHolder div.circleInfoIcon {
  border: 3px solid #d3dbec;
  color: #d3dbec;
  background-color: #9b2743;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover div.ourPartDivColor {
  border-top: 4px solid #c5d6f0;
  background-color: #5e6c89;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover div.redTriangleDiv {
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover div.partTextImgHolderReg {
  opacity: 0;
  transition: all 260ms ease-in-out;
}

div.ourPartnershipBtn:hover div.partTextImgHolderWhite {
  opacity: 1;
  transition: all 260ms ease-in-out;
}

/* about section ends here */

/* services section starts here */

div.servicesSectionHolder {
  position: relative;
  height: 326px;
  width: 100%;
  box-sizing: border-box;
  background-color: #2e3d5b;
  transition: all 260ms ease-in-out;
}

img.serviceBackLftImg {
  position: absolute;
  height: 242px;
  width: auto;
  pointer-events: none;
  left: 0px;
  top: -12px;
  transition: all 260ms ease-in-out;
}

img.serviceBackRghtImg {
  position: absolute;
  height: 215px;
  width: auto;
  pointer-events: none;
  right: 0px;
  top: 199px;
  opacity: 0;
  transition: all 260ms ease-in-out;
}

img.serviceBackRightRespns {
  position: absolute;
  height: 215px;
  width: auto;
  pointer-events: none;
  right: 0px;
  top: 199px;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.ourServeTxt {
  position: absolute;
  display: inline-block;
  font-family: "Lora", serif;
  font-size: 24px;
  letter-spacing: 0.15rem;
  width: auto;
  height: auto;
  color: #ffffff;
  top: 30px;
  left: 95px;
  transition: all 260ms ease-in-out;
}

div.ourServeTxt span {
  margin-left: 28px;
  margin-bottom: -2px;
  display: block;
  position: relative;
  font-weight: 400;
}

div.ourServeTxt b {
  display: block;
  position: relative;
  font-weight: 600;
}

div.mainButtonHolder {
  display: block;
  position: absolute;
  text-align: left;
  width: 220px;
  height: auto;
  left: 230px;
  top: 25px;
  transition: all 260ms ease-in-out;
}

div.servicesButton {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  text-align: left;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 0px 0px 16px;
  background-color: rgba(8, 27, 69, 0.6);
  border: 3.5px solid rgba(106, 121, 151, 0.45);
  font-family: "Lora", serif;
  letter-spacing: 0.085rem;
  font-size: 14px;
  transition: all 260ms ease-in-out;
}

div.servicesButton:hover {
  cursor: pointer;
  background-color: rgba(8, 27, 69, 0.9);
  border: 3.5px solid rgba(106, 121, 151, 0.75);
  transition: all 260ms ease-in-out;
}

span.serviceBtnTxt {
  display: block;
  position: relative;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 9px;
  opacity: 0.7;
  transition: opacity 260ms ease-in-out;
}

div.servicesButton:hover span.serviceBtnTxt {
  opacity: 1;
  transition: opacity 260ms ease-in-out;
}

span.serviceBtnTxt b {
  font-weight: 600;
}

span.serviceBtnTxtSmall {
  display: block;
  position: relative;
  color: #b8c8e0;
  font-weight: 600;
  font-size: 10px;
}

div.inquiryButtonBlue {
  display: inline-block;
  position: relative;
  font-size: 16px;
  width: 145px;
  height: 60px;
  text-align: left;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 0px 0px 16px;
  margin: 6px 0px 0px 6px;
  background-color: rgba(57, 76, 118, 0.6);
  border: 3.5px solid rgba(106, 121, 151, 0.45);
  font-family: "Lora", serif;
  letter-spacing: 0.085rem;
  transition: all 260ms ease-in-out;
}

div.inquiryButtonBlue:hover {
  cursor: pointer;
  background-color: rgba(57, 76, 118, 0.9);
  border: 3.5px solid rgba(106, 121, 151, 0.75);
  transition: all 260ms ease-in-out;
}

div.inquiryButtonRed {
  display: inline-block;
  position: absolute;
  font-size: 16px;
  width: 145px;
  height: 60px;
  text-align: left;
  top: 118px;
  left: -165px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 11px 0px 0px 16px;
  background-color: rgba(94, 108, 137, 0.65);
  border: 3.5px solid rgba(94, 108, 137, 0.8);
  font-family: "Lora", serif;
  letter-spacing: 0.085rem;
  transition: all 260ms ease-in-out;
}

div.inquiryButtonRed:hover {
  cursor: pointer;
  background-color: rgba(153, 25, 50, 0.9);
  border: 3.5px solid rgba(106, 121, 151, 1);
  transition: all 260ms ease-in-out;
}

span.inquiryBtnTxt {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-weight: 600;
  opacity: 0.7;
  top: -4px;
  transition: all 260ms ease-in-out;
}

span.registerBtnTxt {
  display: inline-block;
  position: relative;
  color: #d2e3ff;
  font-weight: 600;
  opacity: 0.9;
  top: -4px;
  transition: all 260ms ease-in-out;
}

div.inquiryButtonBlue:hover span.inquiryBtnTxt,
div.inquiryButtonBlue:hover span.viconInq-inquiry-icon {
  opacity: 1;
  transition: all 260ms ease-in-out;
}

div.inquiryButtonRed:hover span.registerBtnTxt,
div.inquiryButtonRed:hover span.vicon-Register-Icon {
  color: #ffffff;
  opacity: 1;
  transition: all 260ms ease-in-out;
}

span.viconInq-inquiry-icon {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
  margin-right: 6px;
  transition: all 260ms ease-in-out;
}

span.vicon-Register-Icon {
  display: inline-block;
  position: relative;
  color: #d2e3ff;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.85;
  margin-right: 6px;
  top: -2px;
  transition: all 260ms ease-in-out;
}

span.inquiryBtnTxtSmall {
  display: block;
  position: absolute;
  color: #b8c8e0;
  font-weight: 600;
  font-size: 10px;
  bottom: 9px;
  left: 44px;
}

span.registerBtnTxtSmall {
  display: block;
  position: absolute;
  color: #b8c8e0;
  font-weight: 600;
  font-size: 10px;
  bottom: 9px;
  left: 31px;
}

div.disabledTouchScroll {
  display: block;
  position: fixed;
  visibility: visible;
  background: none;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  pointer-events: none;
  touch-action: none;
}

div.enabledTouchScroll {
  display: none;
  visibility: hidden;
  position: absolute;
  background: none;
  height: 0vh;
  width: 0%;
  top: -20px;
  left: -20px;
  pointer-events: none;
  touch-action: none;
}

div.serveInfoShow {
  display: inline-block;
  position: absolute;
  background-color: rgba(8, 27, 69, 0.8);
  border: 3px solid #4b5b7a;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  height: 450px;
  margin: 0% -50% 0% 0%;
  overflow: hidden;
  left: 50%;
  top: 30px;
  opacity: 1;
  transition: left 260ms ease-in-out, margin 260ms ease-in-out;
}

div.serveInfoHide {
  display: inline-block;
  position: absolute;
  background-color: rgba(8, 27, 69, 0.8);
  border: 3px solid #4b5b7a;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  height: 450px;
  margin: 0% 0% 0% 0%;
  left: 101%;
  top: 30px;
  overflow: hidden;
  opacity: 0;
  transition: left 260ms ease-in-out, margin 260ms ease-in-out;
}

div.serveInfoRelInnder {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 55px 0px 10px 10px;
  box-sizing: border-box;
}

#serviceScrollArea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(8, 27, 69, 0.3);
  background-color: rgba(46, 61, 91, 0.75);
  border-radius: 4px;
}

#serviceScrollArea::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(46, 61, 91, 0.75);
}

#serviceScrollArea::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(115, 131, 164, 0.75)),
    color-stop(0.72, rgba(113, 134, 176, 0.75)),
    color-stop(0.86, rgba(120, 140, 174, 0.75))
  );
}

#messageSecFormScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(8, 27, 69, 0.3);
  background-color: rgba(46, 61, 91, 0.75);
  border-radius: 4px;
}

#messageSecFormScroll::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(46, 61, 91, 0.75);
}

#messageSecFormScroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(115, 131, 164, 0.75)),
    color-stop(0.72, rgba(113, 134, 176, 0.75)),
    color-stop(0.86, rgba(120, 140, 174, 0.75))
  );
}

div.serviceInfoScrollBar {
  position: relative;
  font-family: "Lora", serif;
  display: inline-block;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  pointer-events: all;
  touch-action: manipulation;
  text-align: left;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin: 0px 0px 0px 0px !important;
  transition: all 260ms ease-in-out;
}

div.formHolderSection {
  background-color: #4b5b7a;
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 2px solid #2e3d5b;
  padding: 15px 15px;
  box-sizing: border-box;
  text-align: center;
  transition: all 260ms ease-in-out;
}

img.whatExpectImg {
  height: 24px;
  width: 27px;
  display: inline-block;
  margin-right: 14px;
  position: relative;
  top: 3px;
}

h5.whatExpectHead {
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  color: #ffffff;
  margin: 0px !important;
}

div.expectSecShowHide {
  position: relative;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  margin: 0px 0px 22px 0px;
  transition: all 260ms ease-in-out;
}

div.staffServiceSection {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  font-family: "Lora", serif;
  letter-spacing: 0.065rem;
  line-height: 1.6;
  text-align: left;
  transition: all 260ms ease-in-out;
}

div.staffServiceSection p {
  font-weight: 400;
  font-size: 13px;
  color: #ddeaff;
  margin: 0px 0px 12px 0px;
  padding: 0px;
}

div.staffServiceSection h4 {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  margin: 0px 0px 12px 0px;
  padding: 0px;
}

div.staffServiceSection ul {
  margin: 0px !important;
  padding: 0px 0px 0px 20px !important;
}

div.staffServiceSection ul.expectShowListUL {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px !important;
  padding: 20px 0px 0px 0px !important;
  transition: all 100ms ease-in-out;
}

li.serveListLI {
  background: url("./images/VMBSFullLI.svg");
  background-repeat: no-repeat;
  background-position: 0px 5.5px;
  background-size: 11.5px auto;
  font-weight: 400;
  font-size: 12.5px;
  color: #ddeaff;
  display: block;
  text-align: left;
  margin: 0;
  padding: 0px 0 12px 32px;
  list-style: none;
  height: auto;
  width: 90%;
  box-sizing: border-box;
}

span.explainTermText {
  font-size: 12px;
  color: #b3ccff;
  font-weight: 400;
  letter-spacing: 0.07rem;
}

span.explainTermText b {
  font-weight: 600;
}

div.inputHolderInline {
  display: block;
  height: auto;
  width: 97%;
  box-sizing: border-box;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-bottom: 15px;
  transition: all 260ms ease-in-out;
}

div.inputHolderFileIn {
  display: block;
  position: relative;
  height: 45px;
  width: 97%;
  box-sizing: border-box;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-top: 24px;
  margin-bottom: 15px;
  transition: all 260ms ease-in-out;
}

div.textBoxHolderIn {
  display: block;
  position: relative;
  height: auto;
  width: 97%;
  box-sizing: border-box;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-top: 11px;
  margin-bottom: 15px;
  transition: all 260ms ease-in-out;
}

div.submitHolderIn {
  display: block;
  position: relative;
  height: 45px;
  width: 97%;
  box-sizing: border-box;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-top: 17px;
  margin-bottom: 8px;
  transition: all 260ms ease-in-out;
}

div.inputHolderFileIn input {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  outline: none !important;
  border: none !important;
  display: block;
  margin: 0px;
  padding: 0px;
}

div.fileInputCover {
  background-color: #5f7092;
  border: 3px solid #71809e;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 6px 0px 0px 15px;
  text-align: left;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 13.5px;
  transition: all 260ms ease-in-out;
}

div.fileInputChoose {
  background-color: #3c4f76;
  border: 3px solid #93afe5;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 6px 0px 0px 15px;
  text-align: left;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 13.5px;
  transition: all 260ms ease-in-out;
}

div.fileInputChosen {
  background-color: #35476c;
  border: 3px solid #7489b3;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 6px 0px 0px 15px;
  text-align: left;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 13.5px;
  transition: all 260ms ease-in-out;
}

h4.candidateRegHeader {
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 18px;
  position: absolute;
  color: #bbcbeb;
  position: absolute;
  letter-spacing: 0.075rem;
  top: 14px;
  left: 50%;
  margin: 0px 0px 0px -125px !important;
  transition: all 260ms ease-in-out;
}

h4.staffServeHeader {
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 20px;
  position: absolute;
  letter-spacing: 0.085rem;
  color: #bbcbeb;
  position: absolute;
  top: 16px;
  left: 50%;
  margin: 0px 0px 0px -110px !important;
  transition: all 260ms ease-in-out;
}

div.thankYouDivShow {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 10px 35px 0px 15px;
  box-sizing: border-box;
  position: absolute;
  display: block;
  top: 100px;
  opacity: 1;
  pointer-events: none;
  transition: all 260ms ease-in-out;
}

div.thankYouDivHide {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 10px 35px 0px 15px;
  box-sizing: border-box;
  position: absolute;
  display: block;
  top: 140px;
  opacity: 0;
  pointer-events: none;
  transition: all 260ms ease-in-out;
}

h4.thankYouHeader {
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 22px;
  display: block;
  color: #ffffff;
  letter-spacing: 0.075rem;
  line-height: 1.8;
  margin: 0px 0px 15px 0px !important;
}

span.beInTouch {
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 18px;
  height: auto;
  width: 65%;
  line-height: 1.7;
  display: inline-block;
  color: #bed5ff;
  letter-spacing: 0.055rem;
}

div.cancelFile {
  display: inline-block;
  position: absolute;
  right: 11px;
  top: 10px;
  height: 25px;
  width: 25px;
  font-size: 13px;
  padding: 4px 0px 0px 0px;
  text-align: center;
  overflow: hidden;
  border: 2px solid #acb9df;
  color: #acb9df;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #7b8cac;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all 260ms ease-in-out;
}

div.cancelFile:hover {
  cursor: pointer;
  color: #ffffff;
  border: 2px solid #aa2b4a;
  background-image: url("./images/VMBSButBack.svg");
  transition: all 260ms ease-in-out;
}

div.submitInqButton {
  background-color: #36476a;
  border: 3px solid #93afe5;
  border-radius: 4px;
  color: #b2cfff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  margin: 0px !important;
  top: 0px;
  left: 0px;
  padding: 6px 0px 0px 15px;
  text-align: center;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 14px;
  transition: all 260ms ease-in-out;
}

div.submitInqButton-disabled {
  cursor: not-allowed !important;
}

div.submitInqButton:hover {
  cursor: pointer;
  background-color: #081b45;
  border: 3px solid #a1c2ff;
  color: #ffffff;
  transition: all 260ms ease-in-out;
}

div.closeButtonHolder {
  position: absolute;
  display: inline-block;
  top: 10px;
  left: 10px;
  width: 35px;
  height: 35px;
}

div.closeInfoButton {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  overflow: hidden;
  border: 4px solid #57698d;
  border-radius: 6px;
  box-sizing: border-box;
  background-image: url("./images/VMBSButBack.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all 260ms ease-in-out;
}

div.closeInfoButton div.greyBtnBack {
  display: block;
  position: absolute;
  background-color: rgba(122, 139, 174, 1);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  font-size: 15px;
  text-align: center;
  padding: 4.5px 0px 0px 0px;
  box-sizing: border-box;
  transition: background 260ms ease-in-out;
}

div.closeInfoButton div.greyBtnBack span.viconInq-x-icon {
  position: relative;
  display: inline-block;
  color: #081b45;
  transition: color 260ms ease-in-out;
}

div.closeInfoButton:hover {
  cursor: pointer;
  border: 4px solid #738bbb;
  transition: all 260ms ease-in-out;
}

div.closeInfoButton:hover div.greyBtnBack {
  background-color: rgba(122, 139, 174, 0);
  transition: background 260ms ease-in-out;
}

div.closeInfoButton:hover div.greyBtnBack span.viconInq-x-icon {
  color: #ffffff;
  transition: color 260ms ease-in-out;
}

/* services section ends here */

/* contact location section starts here */

div.contactLocationSec {
  position: relative;
  height: 650px;
  width: 100%;
  overflow: hidden;
  font-family: "Lora", serif;
  box-sizing: border-box;
  background-color: #2e3d5b;
  transition: all 260ms ease-in-out;
}

div.contactSectionOuter {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  display: inline-block;
  background: none;
  transition: all 260ms ease-in-out;
}

div.contactWrapper {
  position: relative;
  padding: 50px 0px 0px 40px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 600;
  transition: all 260ms ease-in-out;
}

div.contactText {
  position: absolute;
  height: auto;
  width: auto;
  color: #9eabc0;
  font-size: 19px;
  font-weight: 600;
  top: 5px;
  left: 90px;
  letter-spacing: 0.075rem;
  text-align: left;
  transition: all 260ms ease-in-out;
}

span.viconSec-envelop {
  color: #788db7;
  font-size: 22px;
  display: inline-block;
  position: relative;
  margin-right: 18px;
  transition: all 260ms ease-in-out;
}

span.vicon-Contact-Phone {
  color: #788db7;
  font-size: 23px;
  display: inline-block;
  position: relative;
  margin-right: 15.5px;
  height: auto;
  top: 7px;
  width: auto;
  transition: all 260ms ease-in-out;
}

span.faxContactTxt {
  color: #788db7;
  font-size: 18px;
  display: inline-block;
  position: relative;
  margin-right: 12px;
  margin-left: -7px;
  top: -4px;
  transition: all 260ms ease-in-out;
}

span.orTxt {
  color: #788db7;
  font-size: 15px;
  display: inline;
  position: relative;
  margin-right: 1px;
  margin-left: -12px;
  top: -4px;
  transition: all 260ms ease-in-out;
}

a.phoneConText {
  display: inline-block;
  position: relative;
  color: #a6b4ca !important;
  font-size: 13px;
  font-weight: 600;
  height: auto;
  width: 120px;
  letter-spacing: 0.075rem;
  padding: 0px 5px 0px 5px;
  top: -4px;
  margin: 0px;
  border: none !important;
  text-decoration: none !important;
  transition: all 260ms ease-in-out;
}

a.emailConText {
  display: inline-block;
  position: relative;
  color: #a6b4ca !important;
  font-size: 13px;
  font-weight: 400;
  height: auto;
  width: 290px;
  letter-spacing: 0.075rem;
  padding: 0px 5px 0px 5px;
  top: -4px;
  border: none !important;
  text-decoration: none !important;
  transition: all 260ms ease-in-out;
}

a.phoneConText span {
  display: inline;
  position: relative;
  margin-right: -4px !important;
}

a.emailConText span {
  display: inline;
  position: relative;
  margin-right: 1px !important;
}

a.phoneConText:hover,
a.emailConText:hover {
  cursor: pointer;
  color: #ffffff !important;
  text-decoration: none !important;
  border: none !important;
  transition: all 260ms ease-in-out;
}

a.phoneConText:visited,
a.emailConText:visited {
  color: #a7c5ff !important;
  text-decoration: none !important;
  border: none !important;
  transition: all 260ms ease-in-out;
}

div.socialIcons {
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 34px;
}

div.mapViewIcon {
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0px;
  margin-right: 12px;
  transition: all 260ms ease-in-out;
}

div.socialIconBtn {
  display: inline-block;
  width: 38px;
  height: 34px;
  position: relative;
  background-color: #081b45;
  border: 3px solid #081b45;
  border-radius: 5px;
  color: #939fb3;
  overflow: hidden;
  margin-right: 6px;
  transition: all 260ms ease-in-out;
}

div.socialIconBtn:hover {
  cursor: pointer;
  color: #e4e7e9;
  border: 3px solid #4f5e7c;
  transition: all 260ms ease-in-out;
}

div.socialIconBtn span.vicon-facebook {
  position: absolute;
  bottom: -2px;
  right: 0px;
  font-size: 32px;
  width: auto;
  padding: 0px 0px 0px 0px;
  text-align: right;
  display: inline-block;
}

div.socialIconBtn span.vicon-linkedin2 {
  position: absolute;
  bottom: 5px;
  right: 6px;
  font-size: 26px;
  display: inline-block;
}

div.socialIconBtn span.vicon-twitter {
  position: absolute;
  bottom: 5px;
  right: 6px;
  font-size: 26px;
  display: inline-block;
}

div.locationSectionOuter {
  position: absolute;
  right: 0px;
  top: 250px;
  height: 250px;
  width: 100%;
  display: inline-block;
  text-align: right;
  background-color: #081b45;
  transition: all 260ms ease-in-out;
}

div.locationWrapper {
  position: relative;
  display: inline-block;
  padding: 65px 80px 0px 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  text-align: right;
  box-sizing: border-box;
  z-index: 900;
  pointer-events: none;
  transition: all 260ms ease-in-out;
}

div.locationText {
  position: absolute;
  height: auto;
  width: auto;
  color: #9eabc0;
  font-size: 19px;
  font-weight: 600;
  top: 0px;
  right: 93px;
  letter-spacing: 0.075rem;
  text-align: right;
  transition: all 260ms ease-in-out;
}

span.vicon-Location-Address {
  font-size: 30px;
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
  margin-right: 10px;
  top: -22px;
  left: 0px;
  color: #788db7;
  transition: all 260ms ease-in-out;
}

div.addressHolder {
  display: inline-block;
  width: 200px;
  position: relative;
  color: #a6b4ca;
  font-size: 15px;
  font-weight: 600;
  height: auto;
  width: 185px;
  text-decoration: none !important;
  padding: 0px 15px 0px 0px;
  top: -1px;
  pointer-events: all;
  transition: all 260ms ease-in-out;
}

div.addressHolder:hover {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none !important;
  transition: all 260ms ease-in-out;
}

div.addressHolder:visited {
  color: #a7c5ff !important;
  text-decoration: none !important;
  border: none !important;
  transition: all 260ms ease-in-out;
}

div.addressHolder span.locAddress {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 7px;
}

div.viewMapBtn {
  height: 40px;
  width: 144px;
  text-align: center;
  padding: 10px 0px 0px 0px;
  box-sizing: border-box;
  background-color: rgba(46, 61, 91, 0.6);
  border: 3px solid #46577a;
  border-radius: 5px;
  color: #939fb3;
  font-size: 14px;
  font-weight: 600;
  pointer-events: all;
  transition: all 260ms ease-in-out;
}

div.viewMapBtn:hover {
  cursor: pointer;
  border: 3px solid #939fb3;
  color: #ffffff;
  background-color: rgba(46, 61, 91, 0.6);
  transition: all 260ms ease-in-out;
}

div.viewMapBtn span.vicon-View-Icon {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  width: auto;
  height: auto;
}

div.viewMapBtn span.viewMapText {
  position: relative;
  top: -1px;
  display: inline-block;
}

div.empCandBtn {
  display: inline-block;
  height: 38px;
  width: 144px;
  text-align: center;
  padding: 6.5px 0px 0px 0px;
  box-sizing: border-box;
  background-color: rgba(46, 61, 91, 0.6);
  border: 3px solid #46577a;
  border-radius: 5px;
  color: #b8d3fe;
  font-size: 12px;
  font-weight: 600;
  pointer-events: all;
  margin: 0px 18px 0px 0px;
  transition: all 260ms ease-in-out;
}

div.empCandBtn:hover {
  cursor: pointer;
  border: 3px solid #939fb3;
  color: #ffffff;
  background-color: rgba(46, 61, 91, 0.6);
  transition: all 260ms ease-in-out;
}

div.empCandOppBtn {
  display: inline-block;
  height: 38px;
  width: 144px;
  text-align: center;
  padding: 6.5px 0px 0px 0px;
  box-sizing: border-box;
  background-color: rgba(184, 211, 254, 0.8);
  border: 3px solid #46577a;
  border-radius: 5px;
  color: #2e3d5b;
  font-size: 12px;
  font-weight: 600;
  pointer-events: all;
  margin: 0px 18px 0px 0px;
  transition: all 260ms ease-in-out;
}

div.empCandOppBtn:hover {
  cursor: pointer;
  border: 3px solid #939fb3;
  color: #081b45;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 260ms ease-in-out;
}

div.showGMapDiv {
  position: absolute;
  right: 0px;
  top: 250px;
  width: 80%;
  height: 325px;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  background-color: rgba(59, 74, 104, 0.8);
  border: 3px solid #536382;
  opacity: 1;
  pointer-events: all;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 1200;
  transition: all 260ms ease-in-out;
}

div.hideGMapDiv {
  position: absolute;
  right: -200px;
  top: 250px;
  width: 0%;
  height: 325px;
  box-sizing: border-box;
  text-align: left;
  background-color: rgba(8, 27, 69, 0.8);
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 1100;
  transition: all 260ms ease-in-out;
}

div.mapWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
}

div.mapHolder {
  position: absolute;
  display: inline-block;
  top: 32px;
  height: 260px;
  width: 260px;
  border: 5px solid #51607e;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 260ms ease-in-out;
}

div.hideGMapDiv div.mapWrapper div.mapHolder {
  left: 300px;
  transition: all 260ms ease-in-out;
}

div.showGMapDiv div.mapWrapper div.mapHolder {
  left: 45px;
  transition: all 260ms ease-in-out;
}

div.navyBotDis {
  height: 252px;
  width: 440px;
  position: absolute;
  display: block;
  right: 0px;
  top: -1px;
  background-color: #081b45;
}

img.locationTriangleImg {
  position: absolute;
  height: 250px;
  width: auto;
  right: 1%;
  top: 0px;
  margin-right: -48px;
  display: inline-block;
  transition: all 260ms ease-in-out;
}

img.botLocationLogo {
  position: absolute;
  height: 85px;
  width: auto;
  top: 150px;
  margin-top: -22px;
  right: 1%;
  margin-right: 86px;
  display: inline-block;
  cursor: pointer;
  z-index: 750;
  transition: all 260ms ease-in-out;
}

div.copyRightSecOuter {
  position: absolute;
  display: block;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 175px;
  box-sizing: border-box;
  background-color: #081b45;
  text-align: center;
  padding-top: 75px;
  transition: all 260ms ease-in-out;
}

div.copyRightText {
  width: auto;
  height: auto;
  display: inline-block;
  text-align: center;
}

div.copyRightText span.vicon-Copyright-Icon {
  font-size: 20px;
  color: #8093b9;
  position: relative;
  display: inline;
  top: 1.5px;
  margin-right: 4px;
}

div.copyRightText span.twoTwentyThree {
  font-size: 19px;
  font-family: "Lora", serif;
  font-weight: 600;
  color: #a3bbec;
  position: relative;
  margin-right: 4px;
}

div.copyRightText b.vantageCopy {
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  color: #b42d4e;
  position: relative;
  margin-right: 4px;
}

div.copyRightText span.andText {
  font-size: 19px;
  font-family: "Lora", serif;
  font-weight: 400;
  color: #4e669c;
  position: relative;
  margin-right: 4px;
}

div.copyRightText b.MBSCopy {
  font-size: 19px;
  font-family: "Lora", serif;
  font-weight: 600;
  color: #4e669c;
  position: relative;
}

@media screen and (min-width: 325px) {
  div.homeSecWrapper {
    height: 545px;
    transition: all 260ms ease-in-out;
    -webkit-transition: all 260ms ease-in-out;
  }

  .homeLogoImg {
    width: 140px;
    top: 60px;
    left: 28px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 250px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 250px;
    left: -250px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 350px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 350px;
    right: -350px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 85px;
    left: 180px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 85px;
    left: 180px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 75px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 215px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 215px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 90px;
    transition: all 260ms ease-in-out;
  }

  div.inBtnHolder {
    width: 292px;
    margin-left: 0px;
    transition: all 260ms ease-in-out;
  }

  div.inBtnBottomHolder {
    width: 300px;
    margin-left: -15px;
    transition: all 260ms ease-in-out;
  }

  div.aboutInfoScrollBar {
    width: 340px;
    padding: 0px 15px 0px 5px;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionInfo {
    height: 1420px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionVantage {
    height: 1670px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionMBS {
    height: 2010px;
    transition: height 260ms ease-in-out;
  }

  div.vantageBuidlingHold {
    width: 300px;
    height: 241px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  div.mbsIllustration {
    width: 300px;
    height: 241px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  img.vantageBuilding {
    width: 300px;
    height: 241px;
    transition: all 260ms ease-in-out;
  }

  div.contactWrapper {
    position: relative;
    padding: 47px 0px 0px 25px;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: 0px;
    left: 25px;
    transition: all 260ms ease-in-out;
  }

  div.locationWrapper {
    padding: 80px 32px 0px 0px;
    width: 100%;
    text-align: right;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    height: 195px;
    top: 155px;
    right: -30px;
    opacity: 0;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    height: 195px;
    top: 155px;
    right: -30px;
    opacity: 1;
    transition: all 260ms ease-in-out;
  }
  div.ourServeTxt {
    font-size: 19px;
    top: 55px;
    left: 29px;
    transition: all 260ms ease-in-out;
  }

  div.mainButtonHolder {
    width: 210px;
    left: 145px;
    top: 35px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    padding: 8px 0px 0px 8px;
    font-size: 12px;
    width: 120px;
    height: 55px;
    top: 88px;
    left: -130px;
    transition: all 260ms ease-in-out;
  }
  div.servicesButton {
    height: 70px;
    margin-bottom: 15px;
    padding: 12px 0px 0px 10px;
    font-size: 11px;
    transition: all 260ms ease-in-out;
  }

  div.locationText {
    top: 9px;
    right: 45px;
    text-align: right;
    transition: all 260ms ease-in-out;
  }

  img.locationTriangleImg {
    right: 5%;
    top: 0px;
    margin-right: -90px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    height: 85px;
    margin-top: -50px;
    right: 6%;
    margin-right: 12px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 400px) {
  div.homeSecWrapper {
    height: 565px;
    transition: all 260ms ease-in-out;
  }

  .homeLogoImg {
    width: 150px;
    top: 50px;
    left: 28px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 250px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 250px;
    left: -250px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 400px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 400px;
    right: -400px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 75px;
    left: 200px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 75px;
    left: 200px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 85px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 215px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 215px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 100px;
    transition: all 260ms ease-in-out;
  }

  div.aboutInfoScrollBar {
    width: 380px;
    padding: 0px 15px 0px 5px;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionInfo {
    height: 1380px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionVantage {
    height: 1590px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionMBS {
    height: 1860px;
    transition: height 260ms ease-in-out;
  }

  div.inBtnHolder {
    width: 342px;
    margin-left: 0px;
    transition: all 260ms ease-in-out;
  }

  div.inBtnBottomHolder {
    width: 350px;
    margin-left: -20px;
    transition: all 260ms ease-in-out;
  }

  div.vantageBuidlingHold {
    width: 350px;
    height: 281px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  div.mbsIllustration {
    width: 350px;
    height: 281px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  img.vantageBuilding {
    width: 350px;
    height: 281px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    height: 195px;
    top: 175px;
    right: -15px;
    opacity: 0;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    height: 195px;
    top: 175px;
    right: -15px;
    opacity: 1;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: 0px;
    left: 70px;
    transition: all 260ms ease-in-out;
  }
  div.ourServeTxt {
    font-size: 20px;
    top: 45px;
    left: 59px;
    transition: all 260ms ease-in-out;
  }

  div.mainButtonHolder {
    width: 200px;
    left: 185px;
    top: 35px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    padding: 11px 0px 0px 16px;
    font-size: 14px;
    width: 145px;
    height: 60px;
    top: 90px;
    left: -160px;
    transition: all 260ms ease-in-out;
  }

  div.servicesButton {
    height: 70px;
    margin-bottom: 15px;
    padding: 10px 0px 0px 10px;
    font-size: 13px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 500px) {
  div.messageSecActive {
    width: 440px;
    transition: all 260ms ease-in-out;
  }
  div.messageSecThankyou {
    width: 390px;
    transition: all 260ms ease-in-out;
  }
  div.messFormHolderShow {
    width: 400px;
    right: 15px;
    transition: all 260ms ease-in-out;
  }
  div.messFormHolderHide {
    width: 400px;
    transition: all 260ms ease-in-out;
  }
  div.homeSecWrapper {
    height: 615px;
    transition: all 260ms ease-in-out;
  }
  .homeLogoImg {
    width: 175px;
    top: 50px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 300px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 300px;
    left: -300px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 440px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 440px;
    right: -440px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 80px;
    left: 240px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 80px;
    left: 240px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 105px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 255px;
    left: 70px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 255px;
    left: 70px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 110px;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionInfo {
    height: 1350px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionMBS {
    height: 1755px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionVantage {
    height: 1530px;
    transition: height 260ms ease-in-out;
  }

  div.inBtnHolder {
    width: 392px;
    margin-left: 0px;
    transition: all 260ms ease-in-out;
  }

  div.inBtnBottomHolder {
    width: 400px;
    margin-left: -20px;
    transition: all 260ms ease-in-out;
  }

  div.aboutInfoScrollBar {
    width: 425px;
    padding: 0px 25px 0px 5px;
    transition: all 260ms ease-in-out;
  }

  div.vantageBuidlingHold {
    width: 400px;
    height: 321px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  div.mbsIllustration {
    width: 400px;
    height: 321px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  img.vantageBuilding {
    width: 400px;
    height: 321px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 22px;
    top: 45px;
    left: 65px;
    transition: all 260ms ease-in-out;
  }

  div.mainButtonHolder {
    width: 230px;
    left: 205px;
    top: 35px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    font-size: 15px;
    top: 104px;
    left: -180px;
    transition: all 260ms ease-in-out;
  }

  div.servicesButton {
    height: 80px;
    margin-bottom: 20px;
    padding: 15px 0px 0px 20px;
    font-size: 14px;
    transition: all 260ms ease-in-out;
  }

  div.contactWrapper {
    position: relative;
    padding: 58px 0px 0px 25px;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: 10px;
    left: 70px;
    transition: all 260ms ease-in-out;
  }

  div.locationWrapper {
    padding: 80px 30px 0px 0px;
    width: 100%;
    text-align: right;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    height: 210px;
    top: 179px;
    right: -25px;
    opacity: 0;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    height: 210px;
    top: 179px;
    right: -25px;
    opacity: 1;
    transition: all 260ms ease-in-out;
  }

  div.locationText {
    top: 0px;
    right: 45px;
    text-align: right;
    transition: all 260ms ease-in-out;
  }

  img.locationTriangleImg {
    right: 5%;
    top: 0px;
    margin-right: -100px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    height: 85px;
    margin-top: -42px;
    right: 6%;
    margin-right: 8px;
    transition: all 260ms ease-in-out;
  }
}

/* contact location section ends here */

@media screen and (min-width: 600px) {
  div.navMessageBtn {
    display: inline-block;
    top: 24px;
    left: 94px;
    height: 42px;
    width: 54px;
    transition: all 260ms ease-in-out;
  }
  div.homeSecWrapper {
    height: 730px;
    transition: all 260ms ease-in-out;
  }

  .homeLogoImg {
    width: 175px;
    top: 110px;
    left: 125px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 450px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 450px;
    left: -450px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 540px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 540px;
    right: -540px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 125px;
    left: 335px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 125px;
    left: 335px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 125px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 300px;
    left: 146px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 300px;
    left: 146px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 110px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHold {
    height: 250px;
    pointer-events: all;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionHolder {
    height: 640px;
    margin: 0px 0px -4px 0px;
    transition: all 260ms ease-in-out;
  }

  div.ourPartnershipBtn {
    display: block;
    width: 100%;
    height: 320px;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionInfo {
    height: 1340px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionVantage {
    height: 1470px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionMBS {
    height: 1700px;
    transition: height 260ms ease-in-out;
  }

  div.inBtnHolder {
    width: 442px;
    margin-left: 0px;
    transition: all 260ms ease-in-out;
  }

  div.inBtnBottomHolder {
    width: 450px;
    margin-left: -20px;
    transition: all 260ms ease-in-out;
  }

  div.aboutInfoScrollBar {
    width: 475px;
    padding: 0px 25px 0px 5px;
    transition: all 260ms ease-in-out;
  }

  div.vantageBuidlingHold {
    width: 450px;
    height: 361px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  div.mbsIllustration {
    width: 450px;
    height: 361px;
    margin: 0px 40px 40px 0px;
    transition: all 260ms ease-in-out;
  }

  img.vantageBuilding {
    width: 450px;
    height: 361px;
    transition: all 260ms ease-in-out;
  }

  div.MBSAboutBtn {
    display: inline-block;
    width: 50%;
    height: 320px;
    transition: all 260ms ease-in-out;
  }

  div.vantageAboutBtn {
    display: inline-block;
    width: 50%;
    height: 320px;
    border-bottom: 4px solid #8e9aad;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderReg {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderWhite {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderReg {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -75px;
    background-size: auto 80px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderWhite {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -75px;
    background-size: auto 80px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderReg {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -75px;
    background-size: auto 80px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderWhite {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -75px;
    background-size: auto 80px;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 383px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    top: -12.5px;
    height: 285px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    top: 188px;
    opacity: 0;
    height: 252px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    top: 188px;
    opacity: 1;
    height: 252px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 26px;
    top: 40px;
    left: 115px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 28px;
    margin-bottom: -2px;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 265px;
    top: 35px;
    transition: all 260ms ease-in-out;
  }

  div.servicesButton {
    font-size: 18px;
    padding: 14px 0px 0px 26px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonBlue {
    font-size: 18px;
    width: 175px;
    height: 65px;
    padding: 12px 0px 0px 24px;
    margin: 8px 0px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    font-size: 18px;
    width: 175px;
    height: 65px;
    padding: 12px 0px 0px 24px;
    top: 112px;
    left: -200px;
    transition: all 260ms ease-in-out;
  }

  span.viconInq-inquiry-icon {
    font-size: 21px;
    transition: all 260ms ease-in-out;
  }

  span.vicon-Register-Icon {
    font-size: 21px;
    transition: all 260ms ease-in-out;
  }

  span.inquiryBtnTxtSmall {
    bottom: 9px;
    left: 54px;
  }

  span.registerBtnTxtSmall {
    bottom: 9px;
    left: 34px;
  }

  div.contactWrapper {
    position: relative;
    padding: 48px 0px 0px 55px;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: -10px;
    left: 105px;
    transition: all 260ms ease-in-out;
  }

  div.locationWrapper {
    padding: 75px 70px 0px 0px;
    width: 100%;
    text-align: right;
    transition: all 260ms ease-in-out;
  }

  div.locationText {
    top: -8px;
    right: 85px;
    text-align: right;
    transition: all 260ms ease-in-out;
  }

  span.vicon-Location-Address {
    margin-right: 10px;
    top: -16px;
    transition: all 260ms ease-in-out;
  }

  div.navyBotDis {
    width: 460px;
  }

  img.locationTriangleImg {
    right: 5%;
    top: 0px;
    margin-right: -67px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    height: 85px;
    margin-top: -42px;
    right: 6%;
    margin-right: 41px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 760px) {
  img.locationTriangleImg {
    right: 44%;
    height: 252px;
    top: -1px;
    margin-right: -177px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    right: 44%;
    height: 95px;
    width: auto;
    margin-right: -104px;
    margin-top: -80px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 850px) {
  img.locationTriangleImg {
    right: 52%;
    height: 302px;
    top: -1px;
    margin-right: -153px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    right: 52%;
    height: 100px;
    width: auto;
    margin-right: -80px;
    margin-top: -80px;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionHolder {
    height: 290px;
    transition: all 260ms ease-in-out;
  }

  div.ourPartnershipBtn {
    display: inline-block;
    width: 40%;
    height: 290px;
    border-bottom: 4px solid #8e9aad;
    transition: all 260ms ease-in-out;
  }

  div.MBSAboutBtn {
    display: inline-block;
    width: 30%;
    height: 290px;
    transition: all 260ms ease-in-out;
  }

  div.vantageAboutBtn {
    display: inline-block;
    width: 30%;
    height: 290px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderReg {
    width: 244px;
    height: 84px;
    margin-left: -122px;
    margin-top: -42px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderWhite {
    width: 244px;
    height: 84px;
    margin-left: -122px;
    margin-top: -42px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderReg {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderWhite {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderReg {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderWhite {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 283px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    top: -21px;
    height: 373px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    top: 0px;
    opacity: 1;
    right: 0px;
    height: 330px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    top: 0px;
    opacity: 0;
    right: 0px;
    height: 330px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 30px;
    top: 40px;
    left: 160px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 26px;
    margin-bottom: -2px;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 340px;
    top: 25px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonBlue {
    margin: 8px 0px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    top: 114px;
    left: -205px;
    transition: all 260ms ease-in-out;
  }

  div.contactLocationSec {
    height: 450px;
    transition: all 260ms ease-in-out;
  }

  div.navyBotDis {
    display: none;
  }

  div.contactSectionOuter {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 300px;
    width: 50%;
    transition: all 260ms ease-in-out;
  }

  div.contactWrapper {
    position: relative;
    padding: 52px 0px 0px 123px;
    transition: all 260ms ease-in-out;
  }

  div.locationSectionOuter {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 300px;
    width: 68%;
    transition: all 260ms ease-in-out;
  }

  div.showGMapDiv {
    top: 0px;
    width: 50%;
    transition: all 260ms ease-in-out;
  }

  div.hideGMapDiv {
    top: 0px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 540px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 540px;
    right: -540px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 700px) {
  div.inputHolderInline {
    display: inline-block;
    width: 45%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    transition: all 260ms ease-in-out;
  }

  div.inputHolderFileIn {
    width: 95%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-top: 11px;
    transition: all 260ms ease-in-out;
  }

  div.textBoxHolderIn {
    width: 95%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    transition: all 260ms ease-in-out;
  }

  div.submitHolderIn {
    width: 95%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 760px) {
  div.navMessageBtn {
    top: 242px;
    left: 42px;
    width: 52px;
    height: 39px;
    transition: all 260ms ease-in-out;
  }
  .homeLogoImg {
    width: 180px;
    top: 110px;
    left: 242px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 580px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 580px;
    left: -580px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 600px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 125px;
    left: 460px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 125px;
    left: 460px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 130px;
    transition: all 260ms ease-in-out;
  }
  .topBotTextImg {
    height: 120px;
    transition: all 260ms ease-in-out;
  }
  div.topBotTextHolder {
    top: 310px;
    left: 260px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 310px;
    left: 260px;
    transition: all 260ms ease-in-out;
  }

  .menuLogoFull {
    width: 60px;
    top: 30px;
    right: 0px;
    transition: all 260ms ease-in-out;
  }

  .absoluteItems {
    height: 140px;
    top: 105px;
    pointer-events: all;
    opacity: 1;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHold {
    top: 0px;
    left: 15px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHoldChange {
    top: -200px;
    left: 15px;
    transition: all 160ms ease-in-out;
  }

  div.absoluteNaveHoldHide {
    top: 0px;
    left: 15px;
    transition: all 260ms ease-in-out;
  }

  div.respMenuHolder {
    width: 0px;
    opacity: 0;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 450px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    top: -14px;
    height: 336px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    top: 200px;
    opacity: 0;
    height: 296px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    top: 200px;
    opacity: 1;
    height: 296px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 28px;
    top: 35px;
    left: 150px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 28px;
    margin-bottom: -2px;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 315px;
    top: 35px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonBlue {
    margin: 8px 0px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    top: 114px;
    left: -205px;
    transition: all 260ms ease-in-out;
  }

  div.locationWrapper {
    padding: 67px 0px 0px 181px;
    width: 74%;
    text-align: left;
    transition: all 260ms ease-in-out;
  }

  div.locationText {
    top: 0px;
    left: 230px;
    text-align: left;
    transition: all 260ms ease-in-out;
  }

  span.vicon-Location-Address {
    margin-right: 20px;
    top: -10.5px;
    transition: all 260ms ease-in-out;
  }

  div.mapViewIcon {
    margin-right: 0px;
    margin-left: 40px;
    transition: all 260ms ease-in-out;
  }

  div.serveInfoShow {
    width: 550px;
    height: 400px;
    margin-left: -275px;
    top: 30px;
    transition: all 260ms ease-in-out;
  }

  div.serveInfoHide {
    width: 550px;
    height: 400px;
    top: 30px;
    margin-left: 0px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 850px) {
  div.aboutSectionHolder {
    height: 290px;
    transition: all 260ms ease-in-out;
  }

  div.ourPartnershipBtn {
    display: inline-block;
    width: 40%;
    height: 290px;
    border-bottom: 4px solid #8e9aad;
    transition: all 260ms ease-in-out;
  }

  div.MBSAboutBtn {
    display: inline-block;
    width: 30%;
    height: 290px;
    transition: all 260ms ease-in-out;
  }

  div.vantageAboutBtn {
    display: inline-block;
    width: 30%;
    height: 290px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderReg {
    width: 244px;
    height: 84px;
    margin-left: -122px;
    margin-top: -42px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderWhite {
    width: 244px;
    height: 84px;
    margin-left: -122px;
    margin-top: -42px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderReg {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderWhite {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderReg {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderWhite {
    width: 230px;
    height: 80px;
    margin-left: -115px;
    margin-top: -40px;
    background-size: auto 65px;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 283px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    top: -21px;
    height: 373px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    top: 0px;
    opacity: 1;
    height: 330px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    top: 0px;
    opacity: 0;
    height: 330px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 30px;
    top: 40px;
    left: 160px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 26px;
    margin-bottom: -2px;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 340px;
    top: 25px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonBlue {
    margin: 8px 0px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    top: 114px;
    left: -205px;
    transition: all 260ms ease-in-out;
  }

  div.contactLocationSec {
    height: 450px;
    transition: all 260ms ease-in-out;
  }

  div.navyBotDis {
    display: none;
  }

  div.contactSectionOuter {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 300px;
    width: 50%;
    transition: all 260ms ease-in-out;
  }

  div.contactWrapper {
    padding: 52px 0px 0px 53px;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: 0px;
    left: 98px;
    transition: all 260ms ease-in-out;
  }

  div.locationSectionOuter {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 300px;
    width: 68%;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 978px) {
  div.aboutSectionInfo {
    height: 840px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionVantage {
    height: 1020px;
    transition: height 260ms ease-in-out;
  }

  div.aboutSectionMBS {
    height: 1230px;
    transition: height 260ms ease-in-out;
  }

  div.inBtnHolder {
    width: 230px;
    margin-left: -270px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 1000px) {
  div.navMessageBtn {
    top: 252px;
    left: 52px;
    transition: all 260ms ease-in-out;
  }
  .homeLogoImg {
    width: 200px;
    top: 110px;
    left: 261px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 620px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 620px;
    left: -620px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 715px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 715px;
    right: -715px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 135px;
    left: 495px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 135px;
    left: 495px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 140px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 325px;
    left: 285px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 325px;
    left: 285px;
    transition: all 260ms ease-in-out;
  }

  .menuLogoFull {
    top: 32px;
    transition: all 260ms ease-in-out;
  }

  .absoluteItems {
    top: 108px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHold {
    top: 0px;
    left: 25px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHoldChange {
    top: -200px;
    left: 25px;
    transition: all 160ms ease-in-out;
  }

  div.absoluteNaveHoldHide {
    top: 0px;
    left: 25px;
    transition: all 260ms ease-in-out;
  }

  div.aboutSectionHolder {
    height: 400px;
    transition: all 260ms ease-in-out;
  }

  div.inBtnBottomHolder {
    display: none;
  }

  div.aboutInfoBtnbottomHide {
    display: none;
  }

  div.aboutInfoBtnbottomHold {
    display: none;
  }

  div.aboutInfoScrollBar {
    width: 475px;
    padding: 0px 25px 10px 5px;
    transition: all 260ms ease-in-out;
  }

  div.vantageBuidlingHold {
    width: 450px;
    height: 361px;
    margin: 0px 40px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.mbsIllustration {
    width: 450px;
    height: 361px;
    margin: 0px 40px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  img.vantageBuilding {
    width: 450px;
    height: 361px;
    transition: all 260ms ease-in-out;
  }

  div.ourPartnershipBtn {
    height: 400px;
    transition: all 260ms ease-in-out;
  }

  div.MBSAboutBtn {
    height: 400px;
    transition: all 260ms ease-in-out;
  }

  div.vantageAboutBtn {
    height: 400px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderReg {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderWhite {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderReg {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderWhite {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderReg {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderWhite {
    width: 310px;
    height: 130px;
    margin-left: -155px;
    margin-top: -65px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 314px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    top: -21px;
    height: 420px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    height: 370px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    opacity: 0;
    height: 370px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 32px;
    top: 35px;
    left: 175px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 28px;
    margin-bottom: -3px;
  }

  img.locationTriangleImg {
    right: 55%;
    margin-right: -184px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    height: 100px;
    width: auto;
    right: 55%;
    margin-right: -97px;
    margin-top: -68px;
    transition: all 260ms ease-in-out;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 370px;
    top: 25px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonBlue {
    margin: 12px 0px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    top: 114px;
    left: -215px;
    transition: all 260ms ease-in-out;
  }

  div.serveInfoShow {
    width: 650px;
    height: 450px;
    margin-left: -325px;
    top: 30px;
    transition: all 260ms ease-in-out;
  }

  div.serveInfoHide {
    width: 650px;
    height: 450px;
    top: 30px;
    transition: all 260ms ease-in-out;
  }

  div.contactWrapper {
    padding: 52px 0px 0px 93px;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: 0px;
    left: 138px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 1200px) {
  div.navMessageBtn {
    top: 292px;
    left: 72px;
    transition: all 260ms ease-in-out;
  }
  .homeLogoImg {
    width: 200px;
    top: 150px;
    left: 262px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 670px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 670px;
    left: -670px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 780px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 780px;
    right: -780px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 168px;
    left: 515px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 168px;
    left: 515px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 145px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 372px;
    left: 290px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 372px;
    left: 290px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 125px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 128px;
    transition: all 260ms ease-in-out;
  }

  .menuLogoFull {
    top: 35px;
    transition: all 260ms ease-in-out;
  }

  .absoluteItems {
    top: 115px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHold {
    top: 30px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHoldChange {
    top: -270px;
    left: 45px;
    transition: all 160ms ease-in-out;
  }

  div.absoluteNaveHoldHide {
    top: 30px;
    left: 45px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderReg {
    width: 310px;
    height: 130px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.partTextImgHolderWhite {
    width: 310px;
    height: 130px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderReg {
    width: 310px;
    height: 130px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.vantageImgHolderWhite {
    width: 310px;
    height: 130px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderReg {
    width: 310px;
    height: 130px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.MBSImgHolderWhite {
    width: 310px;
    height: 130px;
    background-size: auto 90px;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 355px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    top: -23px;
    height: 470px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    height: 415px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    opacity: 0;
    height: 415px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 34px;
    top: 55px;
    left: 185px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 28px;
    margin-bottom: -2px;
  }

  div.contactWrapper {
    padding: 52px 0px 0px 123px;
    transition: all 260ms ease-in-out;
  }

  div.contactText {
    top: 0px;
    left: 168px;
    transition: all 260ms ease-in-out;
  }

  img.locationTriangleImg {
    right: 52%;
    margin-right: -184px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    right: 52%;
    margin-right: -97px;
    transition: all 260ms ease-in-out;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 390px;
    top: 55px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonBlue {
    margin: 14px 0px 0px 0px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    top: 115px;
    left: -220px;
    transition: all 260ms ease-in-out;
  }

  div.serveInfoShow {
    width: 630px;
    height: 450px;
    margin-left: -315px;
    top: 30px;
    transition: all 260ms ease-in-out;
  }

  div.serveInfoHide {
    width: 630px;
    height: 450px;
    top: 30px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 1450px) {
  div.navMessageBtn {
    top: 306px;
    left: 66px;
    transition: all 260ms ease-in-out;
  }
  .homeLogoImg {
    width: 220px;
    top: 160px;
    left: 261px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImg {
    width: 700px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 700px;
    left: -700px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 800px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 800px;
    right: -800px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolder {
    top: 180px;
    left: 545px;
    transition: all 260ms ease-in-out;
  }

  div.topTextHolderHide {
    top: 168px;
    left: 515px;
    transition: all 260ms ease-in-out;
  }

  .topTextImg {
    height: 155px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolder {
    top: 405px;
    left: 295px;
    transition: all 260ms ease-in-out;
  }

  div.topBotTextHolderHide {
    top: 405px;
    left: 295px;
    transition: all 260ms ease-in-out;
  }

  .topBotTextImg {
    height: 132px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHold {
    top: 40px;
    left: 40px;
    transition: all 260ms ease-in-out;
  }

  div.absoluteNaveHoldChange {
    top: -260px;
    left: 45px;
    transition: all 160ms ease-in-out;
  }

  div.absoluteNaveHoldHide {
    top: 40px;
    left: 40px;
    transition: all 260ms ease-in-out;
  }

  div.servicesSectionHolder {
    height: 355px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackLftImg {
    height: 500px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRghtImg {
    height: 435px;
    transition: all 260ms ease-in-out;
  }

  img.serviceBackRightRespns {
    opacity: 0;
    height: 435px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt {
    font-size: 34px;
    left: 205px;
    transition: all 260ms ease-in-out;
  }

  div.ourServeTxt span {
    margin-left: 28px;
  }

  img.locationTriangleImg {
    right: 46%;
    margin-right: -184px;
    transition: all 260ms ease-in-out;
  }

  img.botLocationLogo {
    right: 46%;
    margin-right: -99px;
    transition: all 260ms ease-in-out;
  }

  div.mainButtonHolder {
    width: 270px;
    left: 420px;
    top: 75px;
    transition: all 260ms ease-in-out;
  }

  div.inquiryButtonRed {
    top: 115px;
    left: -230px;
    transition: all 260ms ease-in-out;
  }

  div.locationWrapper {
    padding: 85px 0px 0px 281px;
    width: 74%;
    text-align: left;
    -webkit-transition: all 260ms ease-in-out;
    transition: all 260ms ease-in-out;
  }

  div.locationText {
    top: 0px;
    left: 330px;
    text-align: left;
    -webkit-transition: all 260ms ease-in-out;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 1700px) {
  .homeTopLeftImg {
    width: 840px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 840px;
    left: -840px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 950px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 950px;
    right: -950px;
    transition: all 260ms ease-in-out;
  }
}

@media screen and (min-width: 1920px) {
  .homeTopLeftImg {
    width: 900px;
    transition: all 260ms ease-in-out;
  }

  .homeTopLeftImgHid {
    width: 900px;
    left: -900px;
    transition: all 260ms ease-in-out;
  }

  .homeBottomRightImg {
    width: 1100px;
    transition: all 260ms ease-in-out;
  }

  .homeTopRightImgHid {
    width: 1100px;
    right: -1100px;
    transition: all 260ms ease-in-out;
  }
}
