div.formInputWrapper {
  position: relative;
  display: inline-block;
  font-family: "Lora", serif !important;
  font-weight: 600;
  width: 100%;
  height: 55px;
  padding-top: 11px;
  box-sizing: border-box;
  margin: 0px;
}
div.needTextHolder {
    position: absolute;
    width: auto;
    height: auto;
    top: 13.5px;
    right: 2px;
    display: inline-block;
    background-color: #8496b9;
    font-size: 7px;
    letter-spacing: 0.055rem;
    padding: 2px 4px 2px 4px;
    border-bottom: 2px solid #ccdeff;
    border-left: 2px solid #ccdeff;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    transition: all 100ms ease-out;
}
div.formNoTouch {
  position: absolute;
  border: none !important;
  background-color: #4b5b7a;
  height: 24px;
  left: 22px;
  width: 0px;
  top: -5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 100ms ease-out;
}

div.formTouched {
  position: absolute;
  border: none !important;
  background-color: #4b5b7a;
  height: 24px;
  left: 16px;
  width: 100px;
  top: -5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 100ms ease-out;
}

label.labelStart {
  font-size: 11.5px;
  font-weight: 600;
  position: absolute;
  top: 25px;
  left: 20px;
  font-size: 11px;
  letter-spacing: 0.055rem;
  color: #2e3d5b;
  transform: translateY(0);
  text-align: center;
  transition: all 100ms ease-out;
  pointer-events: none;
}

label.labelProblem {
  font-size: 11.5px;
  font-weight: 600;
  position: absolute;
  top: 25px;
  left: 20px;
  font-size: 11px;
  letter-spacing: 0.055rem;
  color: #9b2743;
  transform: translateY(0);
  text-align: center;
  transition: all 100ms ease-out;
  pointer-events: none;
}

label.labelEnd {
  font-size: 11.5px;
  font-weight: 600;
  position: absolute;
  top: 25px;
  letter-spacing: 0.055rem;
  left: 30px;
  font-size: 11.5px;
  color: #ffffff;
  transform: translateY(-23px);
  text-align: center;
  transition: all 100ms ease-out;
  pointer-events: none;
}



div.formInputWrapper > input {
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-family: "Lora", serif !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.5 !important;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px;
  height: 44px;
  padding: 8px 15px 0px 15px !important;
  transition: all 100ms ease-out;
}


div.formInputWrapper > input.formInputNotReady {
  background-color: #ccdeff;
  border: 3px solid #71809e;
  color: #081b45 !important;
  outline: none !important;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

div.formInputWrapper > input.formInputStart {
  background-color: #f0f3f5;
  border: 3px solid #a2b5d8;
  outline: none !important;
  color: #081b45 !important;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

div.formInputWrapper > input.formInputSet {
  background-color: #dfebff !important;
  border: 3px solid #88a2d4;
  outline: none !important;
  color: #314060 !important;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}
