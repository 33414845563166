div.grayPara {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 1;
  padding: 11px 0px 0px 0px; 
  transition: all 250ms ease-out;
}

div.textboxParHolder {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 0px;
    left: 0px;
}

div.interReferenceDiv {
    position: relative;
    width: 100%;
    height: 100%;
}

div.optionTextHolder {
    position: absolute;
    width: auto;
    height: auto;
    top: 12px;
    right: 2px;
    display: inline-block;
    background-color: #8496b9;
    font-size: 7px;
    letter-spacing: 0.055rem;
    padding: 2px 4px 2px 4px;
    border-bottom: 2px solid #ccdeff;
    border-left: 2px solid #ccdeff;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    transition: all 100ms ease-out;
}
div.textNoTouch {
  position: absolute;
  border: none !important;
  background-color: #4b5b7a;
  height: 24px;
  left: 22px;
  width: 0px;
  top: -5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 100ms ease-out;
}

div.textTouched {
  position: absolute;
  border: none !important;
  background-color: #4b5b7a;
  height: 24px;
  left: 16px;
  width: 140px;
  top: -5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 100ms ease-out;
}

label.textLabelStart {
  font-size: 11.5px;
  font-weight: 600;
  position: absolute;
  top: 35px;
  left: 10px;
  font-size: 11px;
  letter-spacing: 0.055rem;
  color: #2e3d5b;
  transform: translateY(0);
  text-align: center;
  pointer-events: none;
  transition: all 100ms ease-out;
}

label.textLabelProblem {
  font-size: 11.5px;
  font-weight: 600;
  position: absolute;
  top: 35px;
  left: 10px;
  font-size: 11px;
  letter-spacing: 0.055rem;
  color: #9b2743;
  transform: translateY(0);
  text-align: center;
  transition: all 100ms ease-out;
  pointer-events: none;
}

label.textLabelEnd {
  font-size: 11.5px;
  font-weight: 600;
  position: absolute;
  top: 35px;
  letter-spacing: 0.055rem;
  left: 30px;
  font-size: 11.5px;
  color: #ffffff;
  transform: translateY(-33px);
  text-align: center;
  transition: all 100ms ease-out;
  pointer-events: none;
}



div.grayPara textarea {
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-family: "Lora", serif !important;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.055rem;
  line-height: 1.5 !important;
  width: 100%;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px !important;
  padding: 12px 15px 15px 15px !important;
  transition: all 100ms ease-out;
}


div.grayPara textarea.textBoxNotReady {
  background-color: #ccdeff;
  border: 3px solid #71809e;
  color: #081b45 !important;
  outline: none !important;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

div.grayPara textarea.textBoxStart {
  background-color: #d8e5ff;
  border: 3px solid #a2b5d8;
  outline: none !important;
  color: #081b45 !important;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

div.grayPara textarea.textBoxSet {
  background-color: #dfebff !important;
  border: 3px solid #88a2d4;
  outline: none !important;
  color: #314060 !important;
  -webkit-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  transition: all 100ms linear;
}

@media screen and (min-width: 500px) {
    label.textLabelStart {
        left: 20px;
        transition: all 100ms ease-out;
    }
    label.textLabelProblem {
        left: 20px;
        transition: all 100ms ease-out;
    }
}
